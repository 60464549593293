.component {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 0;
  overflow: hidden;
  text-align: center;

  & > .headingLayout {
    margin-bottom: var(--size-32);
    margin-left: min(5vw, 2.5vw + 24px);
    margin-right: min(5vw, 2.5vw + 24px);

    @media (--viewport-md) {
      max-width: 550px;
      margin: 0 auto var(--size-55) auto;
    }
  }

  & > .sliderAndButtons {
    @media (--viewport-md) {
      order: 1;
    }
  }

  & > .buttons {
    margin: var(--container-padding);

    @media (--viewport-md) {
      align-self: center;
      margin: 0 0 var(--size-16) 0;
    }
  }
}

.sliderAndButtons {
  display: flex;
  position: relative;
  z-index: 0;
  overflow: hidden;
  text-align: center;
  justify-content: space-around;

  & > .sliderContainer {
    width: 100%;
    max-width: var(--container-max-width-xl);
    margin: 0 auto;
  }

  & > .button {
    flex-grow: 1;
    z-index: 1;
    max-width: 200px;
  }
}

.button {
  position: relative;
  z-index: 0;
  pointer-events: none;
  display: none;
  padding: var(--size-18);

  @media (--viewport-lg) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &::after {
    position: absolute;
    content: "";
    inset: 0;
    background: linear-gradient(
      to left,
      transparent 0%,
      var(--primary-background-color) 50%
    );
    z-index: -1;
  }

  &:nth-child(3) {
    &::after {
      background: linear-gradient(
        to right,
        transparent 0%,
        var(--cutoff-gradient-color, var(--primary-background-color)) 50%
      );
    }
  }

  & > .iconLayout {
    z-index: 1;
    width: var(--size-64);
    height: var(--size-64);
  }
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: var(--size-10);

  @media (--viewport-md) {
    flex-direction: row;
  }
}

.heading {
  font-size: var(--font-size-45);
  font-size: var(--font-size-45-65);
  font-family: var(--font-family-heading);
  line-height: var(--line-height-small-heading);
  text-align: center;
}

.sliderContainer {
  padding: 0 var(--size-18);
  position: relative;
  z-index: 0;

  @media (--viewport-md) {
    overflow: visible;
  }

  & > .slider {
    width: 100%;
  }
}

.slider {
  position: relative;
  display: flex;
  user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  padding: var(--size-32) 0 var(--size-64) 0;

  & > .cardLayout {
    min-height: 410px;
  }
}

.componentIconButton {
  padding: var(--size-20);
  color: var(--primary-color);
  border-radius: 999px;
  visibility: hidden;
  position: relative;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: all;

  &.hide {
    opacity: 0;
  }

  @media (min-width: 1200px) {
    visibility: visible;
  }

  &::before {
    content: "";
    z-index: -1;
    position: absolute;
    border-radius: 999px;
    transition: opacity var(--motion-duration-350) var(--motion-ease);
    outline: 1px solid currentColor;
    opacity: 0.3;
    inset: 0;
  }

  &:hover {
    &::before {
      opacity: 0.5;
    }
  }
}

.component {
  appearance: none;
  user-select: none;
  pointer-events: none;
  font-size: var(--font-size-34);
  font-family: var(--font-family-heading);
  caret-color: var(--primary-color-accent);
  text-align: center;
  color: var(--primary-color--15);
  align-items: center;
  display: flex;
  justify-content: center;

  @media (--viewport-md) {
    font-size: var(--font-size-45);
    font-size: var(--font-size-45-65);
  }
}

.transitionsItem {
  line-height: 1;
}

.component {
  position: relative;
  z-index: 0;
}

.layout {
  display: flex;
  flex-direction: column;

  & > .headingContainer {
    margin-bottom: var(--size-24);
  }

  & > .headingContainer,
  & > .descriptionContainer {
    align-self: center;

    @media (--viewport-md) {
      max-width: var(--container-max-width-sm);
    }
  }

  & > .grid {
    margin-top: var(--size-50);

    @media (--viewport-md) {
      margin-top: var(--size-90);
    }
  }
}

.descriptionContainer {
  text-align: center;
}

.grid {
  display: grid;
  gap: var(--size-32);

  @media (--viewport-md) {
    gap: var(--size-60);
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }

  &.hasMultipleItems {
    @media (--viewport-md) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

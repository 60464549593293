.componentBase {
  position: relative;
  color: var(--primary-color);
  overflow: hidden;
  z-index: 0;
}

.textContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  gap: var(--size-32);
  text-align: center;

  & > .listLayout,
  & > .groupLayout {
    margin-top: 100px;
    width: 100%;
  }
}

.componentList {
  display: grid;
  gap: var(--size-80);
}

.componentGroup {
  & > .groupTitleLayout {
    margin-bottom: var(--size-60);
  }
}

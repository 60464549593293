.page {
  & > .gradientBlurLayout {
    min-height: max(800px, 60vh);
  }

  & > .imageWithText {
    margin-top: -100px;
  }
}

.imageWithText {
  & > * {
    background-color: var(--primary-background-color);
  }

  & > .imageWithTextContainerLayout {
    padding-bottom: var(--size-20);
  }
}

.sectionCareerSlider {
  --padding-top: var(--size-60);

  padding-top: var(--padding-top);

  @media (--viewport-sm) {
    --padding-top: var(--size-80);
  }

  @media (--viewport-md) {
    --padding-top: var(--size-120);
  }

  & > .careerSliderLayout {
    margin-bottom: var(--size-140);
  }
}

.componentChangeCookieSettings {
  display: flex;
  justify-content: center;
}

.otButton {
  color: var(--primary-background-color) !important;
  background-color: var(--primary-color);

  font-weight: var(--font-weight-400);
  text-transform: uppercase;
  font-size: var(--font-size-12);
  letter-spacing: var(--letter-spacing-button);
  border: 1px solid var(--primary-color) !important;
  transition:
    border var(--motion-duration-150) var(--motion-ease-in-out),
    color var(--motion-duration-150) var(--motion-ease-in-out),
    background-color var(--motion-duration-150) var(--motion-ease-in-out);

  &:hover {
    background-color: transparent !important;
    border-color: var(--primary-color) !important;
    color: var(--primary-color) !important;
  }
}

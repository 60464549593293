.page {
  & > .gradientBlurLayout {
    min-height: max(800px, 60vh);
  }

  & > .imageWithText {
    margin-top: -100px;
  }
}

.imageWithText {
  & > * {
    background-color: var(--primary-background-color);
  }
}

.headingContainer {
  display: grid;
  gap: var(--size-24);
  text-align: center;
  justify-content: center;
  color: var(--color-sand-100);
}

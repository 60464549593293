.component {
  display: grid;
  text-align: center;
  align-items: center;
  justify-items: center;
  justify-content: center;
  row-gap: var(--size-20);
  column-gap: var(--size-30-120);
  padding-top: var(--size-32);
  grid-template-rows: 1fr;
  grid-template-areas:
    'image'
    'label'
    'title'
    'introduction';

  @media (--viewport-md) {
    padding-top: 0;
    grid-template-rows: 1fr auto auto auto 1fr;
    grid-template-areas:
      '...          image'
      'label        image'
      'title        image'
      'introduction image'
      '...          image';
  }

  & > .labelLayout {
    grid-area: label;
  }

  & > .titleLayout {
    grid-area: title;
    margin-bottom: var(--size-40);
  }

  & > .introductionLayout {
    grid-area: introduction;
  }

  & > .imageLayout {
    grid-area: image;
  }
}

.type {
  text-transform: uppercase;
  font-family: var(--font-family-heading);
  font-size: var(--font-size-12);
  color: var(--color-copper-300);
}

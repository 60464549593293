.component {
  --menu-item-border-color: var(--primary-color);

  display: var(--desktop-menu-display-value, grid);
  align-items: center;
  justify-content: space-between;
  grid-template-columns: max-content 1fr auto;
  color: var(--accent);
  gap: var(--size-32);

  &.withLoginItem {
    grid-template-columns: max-content 1fr auto auto;
  }

  &.useLargeBreakpoint {
    @media (--viewport-lg) {
      grid-template-columns: minmax(400px, 580px) minmax(600px, 860px) auto;
    }
  }
}

.componentRitualsLogo {
  color: var(--logo);
}

.componentMenuList {
  display: flex;
  justify-content: flex-end;
  gap: var(--size-30);
  gap: var(--size-30-64);
  position: relative;

  & > * {
    flex-grow: 0;
  }
}

.componentMenuItem {
  font-size: var(--font-size-16);
  font-family: var(--font-family-heading);
  line-height: var(--line-height-heading);
  letter-spacing: var(--letter-spacing-heading);
  font-weight: var(--font-weight-400);
  white-space: nowrap;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    opacity: 0;
    transform: scaleX(0);
    transform-origin: left;
    background-color: var(--menu-item-border-color);
    transition:
      transform var(--motion-duration-350) var(--motion-ease),
      opacity var(--motion-duration-200) var(--motion-ease);
  }

  &:hover::before {
    transform: scaleX(1);
    opacity: 1;
  }
}

.languageButton {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, auto);
  font-weight: var(--font-weight-400);
  font-size: var(--font-size-14);
  gap: var(--size-12);

  & > * {
    min-width: var(--size-24);
  }
}

.componentSubMenu {
  --menu-item-border-color: var(--primary-background-color);

  display: flex;
  gap: var(--size-10);
  flex-direction: column;
  color: var(--primary-background-color);
  background-color: var(--secondary-color-accent);
  min-width: 200px !important;
  padding: 36px;
}

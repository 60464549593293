.component {
  color: var(--primary-color);
  background-color: var(--primary-background-color);
  padding-bottom: var(--size-30);
  position: relative;
  overflow: visible;
  z-index: 0;

  &::before {
    content: '';
    z-index: 1;
    position: absolute;
    inset: 0;
    background: linear-gradient(to bottom, transparent 60%, var(--primary-background-color));
    pointer-events: none;
  }
}

.componentImpl {
  --option-count: 0;
  --active-item-index: 0;
  --gap: var(--size-20);

  display: inline-flex;
  padding: var(--size-6);
  border-radius: var(--border-radius-full);
  border: 1px solid var(--primary-color--15);
}

.inner {
  display: grid;
  grid-template-columns: repeat(var(--option-count), 1fr);
  padding: var(--size-10);
  text-align: center;
  position: relative;
  appearance: none;
  gap: var(--gap);
  border: none;
  z-index: 0;

  & > .option {
    margin: 0 var(--size-10);
    min-width: 0;
    z-index: 1;
  }

  &::after {
    content: '';
    top: 0;
    left: 0;
    position: absolute;
    background: var(--primary-color);
    border-radius: var(--border-radius-full);
    transform: translateX(calc(var(--active-item-index) * 100%));
    transition: transform var(--motion-duration-150) var(--motion-ease-in-out-cubic);
    width: calc(100% / var(--option-count));
    height: 100%;
  }
}

.option {
  transition: color var(--motion-duration-150) var(--motion-ease-in-out-cubic);
  letter-spacing: var(--letter-spacing-button);
  font-weight: var(--font-weight-400);
  font-size: var(--font-size-12-14);
  text-transform: uppercase;
  user-select: none;
  color: var(--primary-color);

  &.isActive {
    color: var(--primary-background-color);
  }
}

.isHidden {
  display: none;
}

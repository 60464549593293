.component {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: var(--size-24);
  row-gap: var(--size-40);

  @media (--viewport-md) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media (--viewport-lg) {
    column-gap: var(--size-80);
    row-gap: var(--size-100);
  }
}

.textContainer {
  display: flex;
  flex-direction: column;
  gap: var(--size-20);
}

.item {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  gap: var(--size-12);

  @media (--viewport-md) {
    gap: var(--size-20);
  }

  & > .icon {
    width: var(--size-90);
    height: var(--size-90);

    @media (--viewport-md) {
      width: var(--size-100);
      height: var(--size-100);
    }
  }

  & > .textContainer {
    flex-grow: 1;
  }
}

.title {
  line-height: var(--line-height-heading);
  font-family: var(--font-family-heading);
  letter-spacing: var(--letter-spacing-heading);
  font-weight: var(--font-weight-400);
  font-size: var(--font-size-18);
  font-size: var(--font-size-18-30);
  text-align: center;
}

.icon {
  display: flex;
  align-items: center;
  color: var(--color-clay-400);
}

.iconLayout {
  & > * {
    width: 100%;
    height: 100%;
  }
}

.componentBase {
  --padding-top: var(--size-40);
  --padding-bottom: var(--size-100);

  padding-top: var(--padding-top);
  padding-bottom: var(--padding-bottom);

  &[data-style-context="gray"],
  &[data-style-context="clay"],
  &[data-style-context="black"],
  &[data-style-context="holiday"] {
    background-color: var(--primary-background-color);
    background-image: url("/images/blurred-background.png");
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  &[data-style-context="sand"],
  &[data-style-context="white"] {
    background-color: var(--primary-background-color);
    background-image: url("/images/blurred-background-light-mobile.png");
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;

    @media (--viewport-md) {
      background-image: url("/images/blurred-background-light.png");
    }
  }
}

.componentTransparent {
  & > * {
    --primary-background-color: transparent;
  }
}

.lg {
  --padding-top: var(--size-60);
  --padding-bottom: var(--size-60);

  @media (--viewport-sm) {
    --padding-top: var(--size-80);
    --padding-bottom: var(--size-80);
  }

  @media (--viewport-md) {
    --padding-top: var(--size-120);
    --padding-bottom: var(--size-120);
  }
}

.md {
  --padding-top: var(--size-30);
  --padding-bottom: var(--size-30);

  @media (--viewport-sm) {
    --padding-top: var(--size-40);
    --padding-bottom: var(--size-40);
  }

  @media (--viewport-md) {
    --padding-top: var(--size-60);
    --padding-bottom: var(--size-60);
  }
}

.sm {
  --padding-top: var(--size-16);
  --padding-bottom: var(--size-16);

  @media (--viewport-sm) {
    --padding-top: var(--size-20);
    --padding-bottom: var(--size-20);
  }

  @media (--viewport-md) {
    --padding-top: var(--size-30);
    --padding-bottom: var(--size-30);
  }
}

.component {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 0;
  overflow: hidden;

  & > .headingLayout {
    margin-bottom: var(--size-24);
    text-align: center;

    @media (--viewport-md) {
      order: 1;
    }
  }

  & > .sliderAndButtons {
    @media (--viewport-md) {
      order: 2;
    }
  }

  & > .buttonLayout {
    margin: var(--container-padding);

    @media (--viewport-md) {
      order: 2;
      align-self: center;
    }
  }
}

.sliderAndButtons {
  display: flex;

  & > .sliderContainer {
    width: 100%;
    max-width: var(--container-max-width-sm);
    margin: 0 auto;

    @media (--viewport-md) {
      max-width: 750px;
    }
  }

  & > .sliderButtonLayout {
    flex-grow: 1;
    margin: 0 var(--size-10);
  }
}

.sliderContainer {
  padding: 0 var(--size-18);

  @media (--viewport-md) {
    overflow: hidden;
  }

  & > .slider {
    margin-left: var(--size-24);
    width: 100%;

    @media (--viewport-md) {
      margin-left: 0;
    }
  }
}

.sliderButton {
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider {
  position: relative;
  display: flex;
  user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  padding: var(--size-32) 0 var(--size-64) 0;

  & > .cardLayout {
    min-height: 400px;
  }
}

.componentIconButton {
  display: flex;
  align-items: center;
  justify-content: center;
}

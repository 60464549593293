.componentBase {
  background-color: var(--primary-background-color);
  color: var(--primary-color);
  display: flex;
  flex-direction: column;
  padding: var(--size-40) var(--size-32);
  position: relative;
  z-index: 0;

  @media (--viewport-md) {
    padding: var(--size-32) var(--size-48) var(--size-40)  var(--size-48);
  }

  & > .button {
    align-self: flex-end;
  }

  & > .filtersWrapper {
    margin-bottom: var(--size-48);
    flex-grow: 1;
    z-index: -1;

    @media (--viewport-md) {
      max-height: 100%;
      margin-bottom: var(--size-18);
    }
  }
}

.filtersWrapper {
  position: relative;
  overflow: hidden;

  & > .filters {
    height: 100%;
  }

  &::after {
    @media (--viewport-md) {
      position: absolute;
      display: block;
      content: '';
      bottom: 0;
      width: 100%;
      height: var(--size-120);
      pointer-events: none;
    }
  }

  &[data-style-context="white"] {
    &::after {
      @media (--viewport-md) {
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 40%, var(--color-white) 100%);
      }
    }
  }

  &[data-style-context="black"] {
    &::after {
      @media (--viewport-md) {
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 40%, var(--color-dark-900) 100%);
      }
    }
  }
}

.filters {
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: var(--size-48);
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    height: 0 !important;
  }

  @media (--viewport-md) {
    padding-top: var(--size-24);
    padding-bottom: var(--size-100);
  }
}

.componentOpenCloseButton {
  font-weight: var(--font-weight-400);
  display: flex;
  gap: var(--size-16);
  color: var(--primary-color);
  padding: var(--size-10);
  border-radius: 999px;

  & > .icon {
    margin-top: 2px;
    flex-shrink: 0;
    width: var(--size-20);
    height: var(--size-20);
  }
}

.icon {
  transition: transform var(--motion-duration-200) var(--motion-ease);

  &.rotate {
    transform: rotate(45deg);
  }
}

.filterButtonWrapper {
  display: flex;
  justify-content: center;
  padding: var(--size-18) 0;
}

.componentOpenCloseButtonWithDot {
  position: relative;
  display: inline-block;

  & > .dot {
    position: absolute;
    width: var(--size-8);
    height: var(--size-8);
    right: var(--size-8);
    top: var(--size-8);
  }
}

.dot {
  border-radius: 50%;
  background-color: var(--primary-color);
}

.submitAndReset {
  display: flex;
  flex-direction: column;
  gap: var(--size-16);
}

.resetButton {
  font-weight: var(--font-weight-400);
  font-size: var(--font-size-12);
  text-align: center;
  text-transform: uppercase;
  letter-spacing: var(--letter-spacing-button);
}

.componentDesktopFilters {
  transform: translateY(0);
  transition: transform var(--motion-duration-350) var(--motion-ease-in-out);

  &.isSlidDown {
    transform: translateY(var(--size-70));
  }
}

.componentMobileFilters {
  padding: var(--size-24) 0 0 0;
}

.componentDesktopFilters {
  display: flex;

  & > .selectedFilterBarLayout {
    width: 100%;
  }

  &[data-style-context="white"] {
    background-color: var(--color-sand-100);
  }

  &[data-style-context="black"] {
    background-color: var(--color-dark-900);
  }
}

.componentFloatingDesktopFilters {
  background: rgba(0, 0, 0, 0.2);
}

.desktopFiltersInner {
  pointer-events: none;
  display: flex;
  justify-content: flex-end;

  & > .desktopFiltersLayout {
    pointer-events: auto;
    height: 100lvh;
    width: 100%;
    max-width: 430px;
  }
}

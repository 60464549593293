.component {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: rgba(255, 255, 255, 0.01);
  backdrop-filter: blur(12.5px);
  pointer-events: none;

  & > .iconLayout {
    width: 17px;
    height: 22px;
    transform: translateX(15%);
  }
}

.component {
  position: relative;
  z-index: 0;

  & > .containerLayout {
    z-index: 1;
  }
}

.componentContainerImpl {
  overflow-y: auto;
  background: var(--primary-background-color);

  & > * {
    width: 100%;
  }

  &:not(:empty) {
    outline: 1px solid var(--color-gray-300);
  }
}

.componentOptionImpl {
  display: grid;
  text-align: left;
  align-items: center;
  padding: var(--size-10);
  position: relative;
  cursor: default;
  outline: 0;

  &.isActive {
    background-color: rgba(245, 244, 242, 0.6);
  }
}

.textField {
  --gap: var(--size-10);
  --input-right-padding: calc(var(--size-24) + var(--gap) + var(--size-2));

  display: grid;
  gap: var(--gap);
  align-items: center;
  grid-template-areas: 'input input input';
  grid-template-columns: 1fr var(--size-24) var(--size-2);
  border-bottom: 1px solid var(--primary-color);

  &.isNotEditable {
    background: var(--primary-color--15);
  }

  & > .textPlaceholder {
    grid-area: input;
    width: 100%;
  }

  & > .textInput {
    grid-area: input;
    width: 100%;
  }

  & > .iconButton {
    width: var(--size-24);
    grid-column: 2;
    grid-row: 1;
  }
}

.textPlaceholder {
  padding: var(--size-12) var(--input-right-padding) var(--size-12) var(--size-16);
  color: var(--primary-color);
}

.textInput {
  padding: var(--size-12) var(--input-right-padding) var(--size-12) var(--size-16);
  color: var(--primary-color);

  &::placeholder {
    color: var(--primary-color--40);
    font-style: italic;
  }
}

.iconButton {
  position: relative;

  & > .iconLayout {
    width: var(--size-24);
    height: var(--size-24);
  }
}

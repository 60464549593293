.component {
  --dot-size: var(--size-100);
  --gap: var(--size-30);
  --slides-per-view: 1;

  @media (--viewport-md) {
    --slides-per-view: 3;
  }

  background-color: var(--primary-background-color);
  color: var(--primary-color);
  overflow-x: hidden;
}

.layout {
  position: relative;
  z-index: 0;
  display: grid;
  row-gap: var(--size-100);
  align-items: center;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, auto);
  grid-template-areas: 'text' 'stack';
  padding-top: var(--size-30);
  padding-bottom: var(--size-10);

  @media (--viewport-md) {
    padding: var(--size-120) 0;
  }

  @media (--viewport-md) {
    row-gap: var(--size-30);
    column-gap: calc(var(--gap) * (var(--slides-per-view) - 1));
    grid-template-areas: 'text stack';
    grid-template-rows: 1fr;
    grid-template-columns: 2fr 1fr;
  }

  & > * {
    min-width: 0;
  }

  & > .text {
    grid-area: text;
    align-self: center;

    @media (--viewport-md) {
      padding-right: var(--size-100);
    }
  }

  & > .dot {
    position: relative;
    z-index: 1;
    grid-area: stack;
    margin: auto;
    margin-left: calc(50% - (var(--dot-size) / 2));
    margin-bottom: calc(var(--dot-size) / 2);
    width: var(--dot-size);
    height: var(--dot-size);

    @media (--viewport-md) {
      margin-bottom: auto;
      margin-left: calc(-0.5 * var(--dot-size));
    }

    &.expanded {
      grid-column: 1 / -1;
      margin: auto;
      margin-top: calc(-0.5 * var(--dot-size));
    }
  }

  & > .stack {
    grid-area: stack;
    height: 100%;

    &.expanded {
      grid-column: 1 / -1;
      width: 100%;
    }
  }
}

.iconLayout {
  width: var(--size-30);
  height: var(--size-30);
}

.text {
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  gap: var(--size-30);
  text-align: center;
  transition: opacity var(--motion-duration-200);
  transition-delay: var(--motion-duration-200);

  & > :last-child {
    color: var(--primary-color-accent);
  }

  &.hidden {
    @media (--viewport-md) {
      transition-delay: 0s;
      pointer-events: none;
      opacity: 0;
    }
  }
}

.stack {
  display: flex;
  justify-content: flex-start;

  @media not (--viewport-md) {
    padding: 0 var(--size-30);
  }

  &.expanded {
    cursor: grab;

    &:active {
      cursor: grabbing;
    }

    & > .slidesLayout {
      width: calc((100% - (var(--slides-per-view) - 1) * var(--gap)) / var(--slides-per-view) * var(--slide-count) + var(--gap) * (var(--slide-count) - 1));
      flex-shrink: 0;
    }
  }
}

.componentSlides {
  display: grid;
  user-select: none;
  touch-action: pan-y;

  &.expanded {
    display: flex;
    gap: var(--gap);

    & > .slide {
      min-height: 0;
      align-items: stretch;
      flex: 0 0 calc((100% - var(--gap) * (var(--slide-count) - 1)) / var(--slide-count));
    }
  }

  &:not(.expanded) {
    & > .slide {
      &:first-child {
        @media not (--viewport-md) {
          position: relative;
          bottom: var(--size-12);
          right: var(--size-12);
        }
      }
    }
  }

  & > .slide {
    grid-area: 1 / 1 / -1 / -1;
    height: auto;
    width: auto;
  }
}

.slide {
  transform-origin: top left;
}

.componentSlide {
  & > .slideDescription {
    margin-top: var(--size-10);
  }
}

.slideDescription {
  transition: opacity var(--motion-duration-200);
  font-family: var(--font-family-heading);
  font-size: var(--font-size-22);
  font-size: var(--font-size-22-25);
  line-height: var(--line-height-small-description);
  opacity: 0;

  &.visible {
    opacity: 1;
  }
}

.componentDot {
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: var(--primary-background-color);
  background-color: var(--primary-color);
  font-family: var(--font-family-base);
  border-radius: 999px;
  width: 100%;
  height: 100%;
  letter-spacing: 0.17em;
  font-size: var(--font-size-12);
  font-weight: var(--font-weight-400);
}

.componentArticles {
  & > .paginationAndButtonLayout {
    margin-top: var(--size-90);
  }
}

.loading {
  text-align: center;
}

.button {
  display: flex;
  justify-content: center;
  padding-top: var(--size-18);
}

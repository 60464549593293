.component {
  padding: var(--size-90) 0;

  @media (--viewport-md) {
    padding: var(--size-140) 0;
  }
}

.layout {
  & > .title {
    margin-bottom: var(--size-40);

    @media (--viewport-md) {
      margin-bottom: var(--size-80);
    }
  }
}

.title {
  line-height: var(--line-height-heading);
  font-family: var(--font-family-heading);
  letter-spacing: var(--letter-spacing-heading);
  font-weight: var(--font-weight-400);
  font-size: var(--font-size-45);
  font-size: var(--font-size-45-65);
  text-align: center;
  display: block;
}

.items {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--size-40);

  @media (--viewport-md) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (--viewport-lg) {
    gap: var(--size-80);
  }
}

.item {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: var(--size-20);

  & > .icon {
    width: var(--size-90);
    height: var(--size-90);

    @media (--viewport-md) {
      width: var(--size-120);
      height: var(--size-120);
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: var(--size-8);
}

.value,
.description {
  font-weight: var(--font-weight-400);
  font-family: var(--font-family-heading);
  line-height: var(--line-height-heading);
  text-align: center;
}

.value {
  font-size: var(--font-size-25);

  @media (--viewport-md) {
    font-size: var(--font-size-34);
  }
}

.description {
  font-size: var(--font-size-18);

  @media (--viewport-md) {
    font-size: var(--font-size-25);
  }
}

.icon {
  display: flex;
  align-items: center;
  color: var(--color-clay-400);
}

.iconLayout {
  & > * {
    width: 100%;
    height: 100%;
  }
}

.componentBase {
  & > .selectedItemLayout {
    width: 100%;
    margin: auto;
  }

  &.hasSingleItem {
    cursor: not-allowed;
  }
}

.component {
  border-bottom: 1px solid var(--primary-color);
  background-color: var(--primary-background-color);
  font-weight: var(--font-weight-400);
  letter-spacing: 0.05em;
}

.componentForm {
  border-top: 1px solid transparent;
  background-color: var(--form-input-background);
  color: var(--primary-color);
}

.componentButtonBaseImpl {
  display: grid;
  text-align: left;
  user-select: none;
  align-items: center;
  grid-template-columns: 1fr auto;
  gap: var(--size-10);
  appearance: none;
  position: relative;

  &:focus {
    outline-color: var(--primary-color-accent);
  }

  &.hasSingleItem {
    pointer-events: none;
  }

  &.withAdditionalIcon {
    grid-template-columns: var(--size-20) 1fr auto;
  }

  & > .iconLayout {
    align-self: center;
    justify-self: center;
    height: var(--size-16);
    width: var(--size-16);
  }
}

.componentSelectedFormItemImpl {
  padding: var(--size-12) var(--size-16);
}

.componentButtonFormImpl {
  padding: var(--size-12) var(--size-16);

  &.hasPlaceholder {
    color: var(--primary-color);
    font-style: italic;
  }
}

.componentButtonDefaultImpl {
  font-family: var(--font-family-base);
  font-weight: var(--font-weight-400);
  letter-spacing: 0.05em;
  line-height: 2;
}

.componentContainerImpl {
  overflow-y: auto;
  background: var(--primary-background-color);
  outline: 1px solid var(--primary-color-accent);
}

.componentOptionImpl {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto;
  padding: var(--size-10);
  gap: var(--size-10);
  position: relative;
  cursor: default;
  outline: 0;

  &.isActive {
    background-color: var(--primary-color--40);
  }

  &.withAdditionalIcon {
    grid-template-columns: var(--size-20) 1fr auto;
  }

  & > .iconLayout {
    align-self: center;
    justify-self: center;
  }
}

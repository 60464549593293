.component {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: var(--size-12);

  & > .progressLayout {
    width: 25%;
  }
}

.progress {
  display: flex;

  & > .pageIndicatorLayout {
    flex-grow: 1;
    height: 2px;
  }
}

.hidden {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  overflow: hidden;
  padding: 0;
}

.text {
  color: var(--primary-color);
  font-size: var(--font-size-12);
  font-size: var(--font-size-12-14);
  font-weight: var(--font-weight-350);
  letter-spacing: var(--letter-spacing-paragraph);
  font-style: italic;
}

.componentDash {
  display: flex;
  position: relative;
  background-color: var(--primary-color--40);

  &.isActive {
    background-color: var(--primary-color);
  }

  & > .hidden {
    height: 1px;
    width: 1px;
    margin: -1px;
    overflow: hidden;
    position: absolute;
  }
}

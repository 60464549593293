.component {
  & > .buttonLayout {
    display: inline-block;
    width: 100%;
  }
}

.isDisabled {
  opacity: 0.5;
  pointer-events: none;
}

.componentXs,
.componentAccentXsCentered {
  font-size: var(--font-size-12);
  font-size: var(--font-size-12-14);
  font-weight: var(--font-weight-400);
}

.componentSm,
.componentAccentSmCentered {
  font-size: var(--font-size-16);
  font-size: var(--font-size-16-18);
  font-weight: var(--font-weight-350);
}

.componentMd {
  font-size: var(--font-size-14-21);
  font-weight: var(--font-weight-350);
}

.componentBase {
  color: var(--primary-color);
}

.componentAccentSmCentered {
  color: var(--primary-color-accent);
  text-align: center;
}

.page {
  & > .contentLayout {
    margin-top: var(--size-40);
    margin-bottom: var(--size-40);

    &:not(:last-child) {
      margin-bottom: var(--size-40);
    }
  }
}

.form {
  padding: var(--size-40);
}

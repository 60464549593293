.component {
  --padding-horizontal: min(5vw, calc(2.5vw + 24px));

  padding-inline: var(--padding-horizontal);

  & > .title {
    @media (--viewport-md) {
      max-width: var(--container-max-width-md);
      margin: 0 auto;
    }
  }
}

.title,
.titleWithLgWords {
  font-size: var(--font-size-45);
  font-size: var(--font-size-45-80);
  font-family: var(--font-family-heading);
  letter-spacing: var(--letter-spacing-heading);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-heading);
  padding-block: 2.5em;

  @media (--viewport-md) {
    text-align: center;
    text-transform: uppercase;
  }
}

.titleWithLgWords {
  font-size: var(--font-size-25);
  font-size: var(--font-size-25-30);
  padding-block: 4em 2.5em;

  @media (--viewport-md) {
    font-size: var(--font-size-40);
    font-size: var(--font-size-40-45);
    padding-block: 2.5em;
  }
}

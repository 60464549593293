.page {
  position: relative;
  z-index: 0;
  background-image: url(/images/holiday/bg-sparkles-top-480@2x.jpg),
    url(/images/holiday/bg-sparkles-bottom-480@2x.jpg);
  background-position:
    center top,
    center bottom;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: var(--color-dark-800);

  @media (--viewport-sm) {
    background-image: url(/images/holiday/bg-sparkles-top-1024@2x.jpg),
      url(/images/holiday/bg-sparkles-bottom-1024@2x.jpg);
  }

  @media (--viewport-lg) {
    background-image: url(/images/holiday/bg-sparkles-top-1440@2x.jpg),
      url(/images/holiday/bg-sparkles-bottom-1440@2x.jpg);
  }
}

.heroWrapper {
  & > .heroLayout {
    max-width: 100cqw;

    @media (--viewport-md) {
      max-width: auto;
    }
  }
}

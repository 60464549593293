.component {
  display: flex;
  gap: var(--size-48);
  flex-direction: column;
  padding: var(--size-100) var(--size-32-100) var(--size-70) var(--size-32-100);
  background-color: var(--primary-background-color);
  position: relative;

  & > .blockLogoLayout {
    position: absolute;
    height: var(--size-70);
    width: var(--size-70);
    left: 50%;
    top: 0;
  }

  & > .buttonLayout {
    @media (--viewport-md) {
      display: none;
    }
  }
}

.metaDataContainer {
  --repeat-width: auto;
  --repeat-amount: 1;

  display: grid;
  row-gap: var(--size-8);
  column-gap: var(--size-24);
  justify-content: space-between;
  grid-template-columns: repeat(var(--repeat-amount), var(--repeat-width));

  @media (--viewport-md) {
    --repeat-amount: 2;
  }

  @media (--viewport-lg) {
    --repeat-amount: var(--meta-data-count, 4);
  }
}

.componentMetaItem {
  --repeat-size: 1;

  display: flex;
  align-items: center;
  column-gap: var(--size-8);
  justify-content: flex-start;
  font-size: var(--font-size-14);
  color: var(--secondary-color-accent);

  & > .iconContainer {
    flex: 0 0 auto;
    width: var(--size-30);
    height: var(--size-30);
    min-width: 0;

    @media not (--viewport-md) {
      display: none;
    }
  }
}

.iconContainer {
  color: var(--primary-color-accent);

  & > .iconLayout {
    width: 100%;
    height: 100%;
  }
}

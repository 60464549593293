.page {
  position: relative;
  z-index: 0;
}

.heroWrapper {
  & > .heroLayout {
    max-width: 100cqw;

    @media (--viewport-md) {
      max-width: auto;
    }
  }
}

.component {
  --dot-color: var(--color-soap);
  --after-color: var(--color-sand-100);

  &[data-style-context="clay"],
  &[data-style-context="sand"],
  &[data-style-context="white"] {
    background-color: var(--color-sand-100);
  }

  &[data-style-context="gray"],
  &[data-style-context="black"] {
    --after-color: var(--color-dark-900);
    --dot-color: var(--primary-color);

    background-color: var(--color-dark-900);
  }

  display: flex;
  gap: var(--size-24);
  flex-direction: column;
  align-items: center;
  color: var(--primary-color);
  position: relative;
  z-index: 0;

  @media (--viewport-md) {
    flex-direction: row-reverse;
    justify-content: space-between;
    padding-right: var(--size-40);
  }

  &.isActive {
    &::after {
      background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, var(--after-color) 100%);
    }
  }

  & > .labelsAndTagsLayout {
    width: 100%;

    &.isActive {
      min-height: var(--size-70);
    }
  }
}

.componentLabelAndTags {
  &.isActive {
    &::after {
      position: absolute;
      display: block;
      content: '';
      right: 0;
      height: 100%;
      max-height: var(--size-70);
      width: var(--size-80);
      pointer-events: none;
      bottom: 0;

      @media (--viewport-md) {
        display: none;
      }
    }
  }
}

.componentLabelAndTags {
  display: flex;
  column-gap: var(--size-16);
  row-gap: var(--size-10);
  align-items: center;
  flex-wrap: nowrap;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  scrollbar-width: none;
  -ms-overflow-style: none;
  white-space: nowrap;
  position: relative;
  z-index: 0;

  &.isActive {
    padding: 0 var(--size-40) 0 var(--size-20);

    @media (--viewport-md) {
      padding: var(--size-10) 0;
    }
  }

  @media (--viewport-md) {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-areas: 'label tags';
  }

  & > .labelLayout {
    @media (--viewport-md) {
      grid-area: label;
    }
  }

  & > .tagsLayout {
    @media (--viewport-md) {
      grid-area: tags;
    }
  }

  &::-webkit-scrollbar {
    height: 0 !important;
  }
}

.componentTags {
  display: flex;
  column-gap: var(--size-10);
  row-gap: var(--size-10);
  align-items: center;
  flex-wrap: nowrap;

  @media (--viewport-md) {
    flex-wrap: wrap;
  }
}

.label {
  font-style: italic;

  @media (--viewport-md) {
    padding-left: var(--size-20);
  }

  @media (--viewport-lg) {
    padding-left: var(--size-40);
  }
}

.componentTag {
  display: flex;
  gap: var(--size-8);
  align-items: center;
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-400);
  border: 1px solid var(--primary-color--15);
  border-radius: var(--size-24);
  padding: 2px var(--size-14) 1px var(--size-16);

  & > .tagIconLayout {
    width: var(--size-12);
    height: var(--size-12);
    flex-shrink: 0;
  }
}

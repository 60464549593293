.component {
  background-color: var(--color-dark-900);
  padding-top: var(--size-100);
  padding-bottom: var(--size-50);
}

.atsDepartmentsInner {
  display: grid;
  gap: var(--size-80);
}

.headingContainer {
  text-align: center;
}

.atsDepartmentCards {
  display: grid;
  column-gap: var(--size-20);
  row-gap: var(--size-20);
  grid-template-columns: repeat(2, 1fr);

  @media (--viewport-sm) {
    column-gap: var(--size-40);
    row-gap: var(--size-40);
  }

  @media (--viewport-md) {
    grid-template-columns: repeat(3, 1fr);
  }

  & > .cardLayout {
    @media (--viewport-md) {
      max-width: 80vw;
    }
  }
}

.componentCard {
  color: var(--color-dark-900);
  background-color: var(--color-white);
  animation: fadeInCard 0.5s ease-in-out;

  @media (--viewport-md) {
    animation: unset;
  }
}

.titleContainer {
  padding: var(--size-20);
  text-align: center;
}

.titleElement {
  font-family: var(--font-family-heading);
  line-height: var(--line-height-small-heading);

  @media (--viewport-md) {
    line-height: unset;
  }
}

.loadMoreContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--size-10);
}

@keyframes fadeInCard {
  from { opacity: 0; }
  to { opacity: 1; }
}

.component {
  background-color: var(--color-dark-900);
  font-weight: var(--font-weight-400);
  text-align: center;
  isolation: isolate;
  display: flex;
  flex-direction: column;
}

.footerTopLayout {
  display: flex;
  flex-direction: column;
  padding-top: var(--size-100);

  & > .logoLayout {
    margin-bottom: var(--size-55);
    align-self: center;
  }

  & > .columns {
    margin-bottom: var(--size-60);
  }
}

.footerBottomLayout {
  padding-bottom: var(--size-60);

  & > .socialsLayout {
    margin-bottom: var(--size-55);
    align-self: center;
  }

  & > .logosLayout {
    align-self: center;
  }
}

.columns {
  display: grid;
  grid-template-columns: 1fr;
  color: var(--color-gray-300);
  gap: var(--size-40);

  @media (--viewport-md) {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: var(--size-50);
  }
}

.componentSocials {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--color-gray-300);

  & > .socials {
    margin-bottom: var(--size-30);
  }
}

.links {
  display: flex;
  column-gap: var(--size-30);
  column-gap: var(--size-30-40);
  justify-content: center;
  flex-direction: column;

  &:not(:has(:nth-child(5))) {
    /* when we have less than five links, switch to horizontal layout at -viewport-md */
    @media (--viewport-md) {
      flex-direction: row;
      row-gap: var(--size-14);
      line-height: var(--line-height-small-description);
    }
  }

  @media (--viewport-lg) {
    flex-direction: row;
    row-gap: var(--size-14);
    line-height: var(--line-height-small-description);
  }
}

.socials {
  display: flex;
  justify-content: center;
  gap: var(--size-18);
}

.socialsLinkLayout,
.loginLayout {
  opacity: 0.4;
}

.componentLogos {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: var(--size-30);

  & > .ritualsLogoLayout {
    max-width: var(--size-48);
    height: auto;
  }

  & > .bCorpLayout {
    max-width: var(--size-60);
    height: auto;
  }
}

.componentExtraNotice {
  background-color: var(--color-dark-800);
  color: var(--color-gray-300);
  font-size: var(--size-14);
  padding: var(--size-24) 0;
  text-align: center;
  font-weight: var(--font-weight-350);
}

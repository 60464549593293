.componentHorizontal {
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 0;

  @media (--viewport-md) {
    flex-direction: row;
  }

  & > .content {
    position: static;
    z-index: 1;

    @media (--viewport-md) {
      width: 50%;
    }
  }

  & > .coverImage {
    order: -1;
    max-width: 100%;

    @media (--viewport-md) {
      order: 0;
      width: 50%;
    }
  }
}

.cardVerticalBase {
  --order-image-first: 0;
  --order-image-last: -1;

  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 0;

  & > .content {
    position: static;
    z-index: 1;
    flex-grow: 1;

    @media (--viewport-md) {
      width: 100%;
    }
  }

  & > .coverImage {
    order: var(--order-image-last);
    margin-top: auto;

    @media (--viewport-md) {
      order: var(--order-image-first);
      width: 100%;
    }
  }
}

.isReversed {
  --order-image-first: -1;
  --order-image-last: 0;
}

.componentBase {
  display: flex;
  flex-direction: column;
  background-color: var(--primary-background-color);
  color: var(--primary-color);
  text-align: center;
  position: relative;
  z-index: 0;

  @supports (container-type: inline-size) {
    container-type: inline-size;
  }
}

.content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: var(--size-32) var(--size-64);
  position: relative;

  @media (--viewport-md) {
    padding: var(--size-55) var(--size-40);
  }

  & > .titleLayout {
    margin-bottom: var(--size-18);
  }

  & > .type {
    margin-bottom: var(--size-10);
  }

  & > .description {
    margin-bottom: var(--size-18);
  }

  & > .buttonLayout {
    position: static;
  }
}

.type {
  text-transform: uppercase;
  font-family: var(--font-family-heading);
  font-size: var(--font-size-12);
  color: var(--color-copper-300);
  display: none;

  @media (--viewport-md) {
    display: block;
  }
}

.coverImage {
  position: relative;

  &::before {
    display: block;
    content: '';
    width: 100%;
    padding-top: 100%;
  }

  & > .imageLayout {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
  }
}

.headingContainer,
.descriptionContainer {
  transform: translateX(-50%);
}

.description {
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-400);
}

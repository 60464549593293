.componentXxl,
.componentAccentXxl,
.componentPlainXxl {
  font-size: var(--font-size-45);
  font-size: var(--font-size-45-80);

  @media (--viewport-md) {
    font-size: var(--font-size-70);
    font-size: var(--font-size-70-148);
  }
}

.componentXl,
.componentXlTitleSubTitle {
  font-size: var(--font-size-45);
  font-size: var(--font-size-45-80);
}

.componentMd,
.componentPlainMd {
  font-size: var(--font-size-30);
  font-size: var(--font-size-30-45);

  @media (--viewport-md) {
    font-size: var(--font-size-56);
    font-size: var(--font-size-56-65);
  }
}

.componentBase {
  color: var(--primary-color);
  display: inline-flex;
  flex-direction: column;
  font-family: var(--font-family-heading);
  line-height: var(--line-height-uppercase-heading);
  text-transform: uppercase;
  white-space: break-spaces;
  overflow-wrap: break-word;
  word-break: break-word;
  align-items: center;
  text-align: center;
  hyphens: auto;
  text-wrap: balance;
}

.accent {
  color: var(--primary-color-accent);
}

.componentTitle {
  color: inherit;
  font-size: inherit;
  word-break: break-word;

  & > .subtitleLayout {
    margin-top: 0;
    margin-bottom: 0.25em;
  }
}

.componentSubTitle {
  display: inline-flex;
  text-transform: initial;
  color: var(--primary-color-accent);
  line-height: var(--line-height-alt);
  font-family: var(--font-family-alt);
  font-size: 130%;

  &:not(:last-child) {
    padding-bottom: var(--size-18);

    @media (--viewport-md) {
      padding-bottom: var(--size-22);
    }
  }
}

.componentTitleBase {
  word-break: break-word;
}

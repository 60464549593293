.component {
  display: grid;
  place-content: center;
  transform-origin: center center;

  & > .inner {
    max-width: min(50vw, 700px);
    max-height: 50svh;
  }
}

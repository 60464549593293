.component {
  display: flex;
  gap: var(--size-32-100);
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  padding: var(--container-padding);
  background-color: var(--primary-background-color);
  color: var(--primary-color);
  z-index: 0;

  @media (--viewport-xl) {
    flex-direction: row;
    justify-content: space-between;
  }

  & > .content {
    min-width: min(calc(350px + (2 * var(--size-40))), calc(100vw - var(--container-x-padding)));
    margin: 0 0 var(--size-40) 0;

    @media (--viewport-xl) {
      margin: 0 auto;
    }
  }

  & > .sliderContainer {
    width: 100vw;
    margin: 0 auto;

    @media (--viewport-md) {
      max-width: var(--container-max-width-lg);
    }
  }

  & > .iconLeftLayout,
  & > .iconRightLayout {
    z-index: 2;
    flex-shrink: 0;
  }
}

.iconLeftLayout,
.iconRightLayout {
  &.isHidden {
    opacity: 0;
  }
}

.content {
  text-align: center;
  padding: var(--container-padding);
  transition: all var(--motion-duration-200);

  &.slideOne {
    @media (--viewport-xl) {
      filter: blur(0.5rem);
      transform: scale(0.9);
    }
  }

  &.slideTwo {
    @media (--viewport-xl) {
      filter: blur(0.5rem);
      transform: scale(0.5);
      opacity: 0;
    }
  }

  @media (--viewport-xl) {
    text-align: left;
  }

  & > .subHeadingLayout {
    margin-top: var(--size-24);

    @media (--viewport-md) {
      margin-top: var(--size-30);
    }
  }

  & > .headingLayout,
  & > .subHeadingLayout {
    @media (--viewport-xl) {
      max-width: 400px;
    }
  }
}

.sliderContainer {
  padding: var(--container-padding);
  position: relative;
  z-index: 0;

  @media (--viewport-xl) {
    padding: 0;
  }

  & > .slider {
    width: 100%;

    @media (--viewport-lg) {
      margin-left: 0;
    }
  }
}

.slider {
  position: relative;
  display: flex;
  user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  cursor: grab;
  overflow: visible;
  padding-bottom: var(--size-30);

  @supports (container-type: inline-size) {
    container-type: inline-size;
  }

  & > * {
    min-width: 330px;
    max-width: 350px;
  }
}

.componentCard {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  box-shadow: var(--shadow-card);
  background-color: var(--primary-background-color);
  color: var(--primary-color);
  text-align: center;

  & > .coverImage {
    max-height: 400px;
    width: 100%;
  }

  & > .headingLayout {
    min-height: 2lh;
    margin: var(--size-20);

    @media (--viewport-md) {
      margin: var(--size-32);
    }
  }

  & > .buttonLayout {
    margin-top: auto;
    margin-bottom: var(--size-40);
  }
}

.coverImage {
  position: relative;

  &::before {
    display: block;
    content: '';
    width: 100%;
    padding-top: calc((8 / 7) * 100%);
  }

  & > .imageLayout {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
  }
}

.imageLayout {
  object-fit: cover;

  & > * {
    height: 100%;
  }
}

.component {
  display: flex;
  padding: var(--size-18) var(--container-x-padding) var(--size-24);
  pointer-events: none;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 0.55) 100%
  );
  transition:
    transform var(--motion-duration-350) var(--motion-ease-in-out),
    background-color var(--motion-duration-350) var(--motion-ease),
    color var(--motion-duration-200) var(--motion-ease);

  @media (--viewport-lg) {
    display: none;
  }

  & > .buttonContainer {
    width: 100%;
  }

  &.isHidden {
    transform: translateY(-100%);
  }
}

.buttonContainer {
  pointer-events: all;
}

.page {
  display: flex;
  flex-direction: column;
  position: relative;
}

.faqSection {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: var(--size-70);

  & > .faqLayout {
    width: 100%;
  }
}

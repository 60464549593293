.page {
  position: relative;
  z-index: 0;

  & > .gradientLayout {
    position: fixed;
    height: 100%;
    width: 100%;
    inset: 0;
  }

  & > .backgroundLayout {
    position: absolute;
    z-index: 0;
    inset: 0;
  }
}

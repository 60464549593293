.page {
  display: flex;
  flex-direction: column;
  position: relative;

  & > .benefitsLayout {
    margin-bottom: var(--size-30);

    @media (--viewport-md) {
      margin-bottom: var(--size-60);
    }
  }
}

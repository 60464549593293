.component {
  & > * {
    width: 100%;
    min-height: unset;

    @media (--viewport-md) {
      height: auto;
    }
  }
}

.component {
  display: var(--mobile-menu-display-value, grid);

  & > .menuContainer {
    margin: var(--size-20);
  }
}

.menuContainer {
  display: grid;
  gap: var(--size-20);
  align-items: center;
  grid-template-columns: auto 1fr;
  grid-template-areas: 'button logo';
  color: var(--accent);

  & > .logoLayout {
    grid-area: logo;
  }

  & > .hamburgerButton {
    grid-area: button;
  }
}

.hamburgerButton {
  color: var(--logo);
}

._rootOverlay {
  position: fixed;
  background-color: var(--primary-background-color);
  color: var(--primary-color);
  height: 100vh;
  height: 100dvh;
  width: 100vw;
  inset: 0;

  & > .container {
    height: 100%;
  }
}

.container {
  display: grid;
  align-items: center;
  padding: var(--size-20);
  column-gap: var(--size-20);
  grid-template-columns: var(--size-30) 1fr;
  grid-template-rows: auto var(--size-60) 1fr var(--size-10) min-content var(--size-30) min-content;
  grid-template-areas:
    'close      logo'
    '...        ...'
    'content    content'
    '...        ...'
    'log-out    log-out'
    '...        ...'
    'language   language';

  & > .closeIconLayout {
    grid-area: close;
    width: var(--size-30);
    height: var(--size-30);
  }

  & > .logoLayout {
    grid-area: logo;
  }

  & > .menuListLayout {
    grid-area: content;
    height: 100%;
  }

  & > .logoutLayout {
    grid-area: log-out;
  }

  & > .languageSelectionLayout {
    grid-area: language;
  }
}

.componentRitualsLogo {
  color: var(--logo);
}

.componentMenuList {
  display: flex;
  flex-direction: column;
  padding-left: calc(var(--size-30) + var(--size-20));
  gap: var(--size-14);
}

.componentMenuItem {
  display: flex;
  gap: var(--size-10);
  justify-content: space-between;
  font-family: var(--font-family-heading);
  line-height: var(--line-height-small-heading);
  text-align: left;
  font-size: var(--font-size-22);

  @media (--viewport-height-md) {
    font-size: var(--font-size-28);
  }

  & > .labelLayout {
    flex: 1 auto;
  }

  & > .iconLayout {
    flex: 0 0 auto;
    justify-self: end;
  }
}

.componentSubMenu {
  display: grid;
  gap: var(--size-20);
  grid-template-columns: var(--size-30) 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'back   title'
    '...    content';

  & > .subMenuHeaderTitle {
    grid-area: title;
  }

  & > .subMenuBackButton {
    grid-area: back;
  }

  & > .subMenuList {
    grid-area: content;
  }
}

.subMenuHeaderTitle {
  position: relative;
  font-family: var(--font-family-heading);
  font-size: var(--font-size-28);
  line-height: var(--line-height-heading);
}

.componentSubMenuItem {
  padding-bottom: var(--size-6);
  padding-top: var(--size-6);
}

.subMenuItemAnchor {
  text-align: left;
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-small-heading);
}

.componentStagger {
  & > * {
    width: 100%;
  }
}

.componentBase {
  & > .littleActsOfKindnessLayout {
    margin-top: var(--size-30);
    margin-bottom: var(--size-30);

    @media (--viewport-md) {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}

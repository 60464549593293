.page {
  position: relative;
  z-index: 0;

  & > .content {
    z-index: 1;
    position: relative;
  }
}

.content {
  padding-top: var(--size-40);
  padding-bottom: var(--size-40);
}

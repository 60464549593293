
.component {
  --mobile-menu-height: 68px;

  z-index: 0;
  position: relative;
  display: grid;
  background-color: var(--primary-background-color);
  padding-top: var(--mobile-menu-height);

  @media (--viewport-md) {
    padding-top: 0;
  }

  & > .contentLayout {
    grid-area: 1 / -1;
  }

  & > .images {
    z-index: -1;
    grid-area: 1 / -1;
  }
}

.componentContent {
  display: none;
  padding: var(--size-24) var(--size-32);
  color: var(--primary-color);
  transform: translateY(0);
  transition:
    opacity var(--motion-duration-200) var(--motion-ease),
    transform var(--motion-duration-350) var(--motion-ease);

  @media (--viewport-lg) {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: var(--size-32);
  }

  @media (--viewport-xxl) {
    padding: var(--size-50);
  }

  &.isHidden {
    pointer-events: none;
    opacity: 0;
    transform: translateY(var(--size-32));
  }

  & > .title {
    max-width: 500px;
    margin-bottom: var(--size-24);

    @media (--viewport-lg) {
      max-width: 90%;
    }
  }
}

.title {
  font-size: var(--font-size-45);
  font-size: var(--font-size-45-50);
  font-family: var(--font-family-heading);
  line-height: var(--line-height-heading);
  letter-spacing: var(--letter-spacing-heading);
  font-weight: var(--font-weight-400);
  padding-top: 190px;

  @media (--viewport-lg) {
    font-size: var(--font-size-40);
  }

  @media (--viewport-lg) {
    padding-top: 0;
  }
}

.buttonAndJobInfo {
  display: flex;
  flex-direction: column;

  & > .jobInfo {
    margin-bottom: var(--size-12);

    @media (--viewport-lg) {
      flex-grow: 1;
    }

    @media (--viewport-xl) {
      margin-bottom: 0;
    }
  }

  @media (--viewport-xl) {
    flex-direction: row;
    gap: var(--size-18);

    @media (--viewport-xxl) {
      gap: var(--size-40);
    }
  }

  & > .buttonLayout {
    @media (--viewport-xl) {
      order: -1;
    }
  }
}

.buttonLayout {
  padding: var(--size-12) var(--size-22);
  white-space: nowrap;

  @media (--viewport-xxl) {
    padding: var(--size-14) var(--size-32);
  }
}

.jobInfo {
  display: flex;
  font-weight: var(--font-weight-400);
  align-items: center;
  gap: var(--size-14);
  line-height: var(--line-height-small-heading);
  font-size: var(--font-size-18);

  @media (--viewport-lg) {
    font-size: var(--font-size-16);
  }
}

.images {
  position: relative;
  z-index: 0;

  & > .imagesLayout {
    position: absolute;
    inset: 0;

    &:nth-child(1) { z-index: 0; }
    &:nth-child(2) { z-index: 1; }
    &:nth-child(3) { z-index: 2; }
    &:nth-child(4) { z-index: 3; }
  }
}

.componentImageJob {
  position: relative;
  color: var(--primary-color);
  z-index: 0;
  transition: opacity 600ms;

  &:nth-child(2),
  &:nth-child(3),
  &:nth-child(4) {
    opacity: 0;
  }

  &.introduction {
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      opacity: 0;
    }
  }

  &.description {
    &:nth-child(2) {
      opacity: 1;
    }
  }

  &.benefits {
    &:nth-child(2),
    &:nth-child(3) {
      opacity: 1;
    }
  }

  &.form {
    &:nth-child(3),
    &:nth-child(4) {
      opacity: 1;
    }
  }

  & > .imageLayout {
    width: 100%;
    height: 100%;
  }

  & > .overlay {
    position: absolute;
    inset: 0;
  }

  &::after {
    position: absolute;
    display: block;
    content: '';
    inset: 0;
    z-index: 1;
    background-color: var(--color-black);
    opacity: 0.15;
  }
}

.overlay {
  opacity: 0;
  -webkit-mask-image: linear-gradient(180deg, transparent 70%, black 80%);
  mask-image: linear-gradient(180deg, transparent 60%, black 80%);
  overflow: hidden;
  transition: opacity var(--motion-duration-350) var(--motion-ease);
  display: none;

  @media (--viewport-lg) {
    display: block;
  }

  & > .imageLayout {
    width: 100%;
    height: 100%;
  }

  &.showOverlay {
    opacity: 1;
  }
}

.imageLayout {
  & > * {
    height: 100%;
  }
}

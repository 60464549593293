.componentBase {
  --offset: 100px;

  display: grid;
  grid-template-columns: 1fr;
  column-gap: var(--size-60);
  row-gap: var(--size-30);

  @media (--viewport-md) {
    row-gap: 0;
  }

  &.withOffset {
    z-index: 0;
    padding-top: 0;
    position: relative;
    row-gap: var(--size-60);
    grid-template-rows: auto 1fr;
    grid-template-areas:
      'image'
      'content';

    @media (--viewport-md) {
      padding-top: 0;
      grid-template-rows: var(--offset) 1fr 50px;
      grid-template-areas:
        '...      image'
        'content  image'
        '...      image';
    }

    & > .content {
      grid-area: content;
    }

    & > .coverImage {
      grid-area: image;
      z-index: 2;
    }
  }

  @media (--viewport-md) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--viewport-lg) {
    gap: var(--size-80);
  }

  & > .coverImage {
    &.isTop {
      order: -1;

      @media (--viewport-md) {
        order: 0;
      }
    }

    &.isLeft {
      @media (--viewport-md) {
        order: -1;
      }
    }
  }
}

.content {
  display: flex;
  flex-direction: column;

  @media (--viewport-md) {
    justify-content: flex-end;
  }

  @media (--viewport-lg) {
    padding: 0 var(--size-50) var(--size-100) var(--size-40);
  }

  & > .linkLayout {
    margin-top: var(--size-50);

    @media (--viewport-md) {
      margin-top: var(--size-64);
      align-self: flex-start;
    }
  }
}

.text {
  font-size: var(--font-size-16);
  font-size: var(--font-size-16-18);

  & > .checklistLayout {
    margin-top: var(--size-32);
  }
}

.coverImage {
  & > .imageLayout {
    width: 100%;

    @media (--viewport-md) {
      height: 100%;
    }

    @media (--viewport-lg) {
      height: auto;
    }
  }
}

.imageLayout {
  object-fit: cover;
}

.overlay,
.floating {
  & > * {
    width: 100%;
    height: 100%;
  }
}

.componentImpl {
  position: relative;
  z-index: 0;
  display: grid;
  align-items: center;
  background-image: var(--gradient, var(--gradient-gold));
  color: var(--color-white);
  padding: var(--size-40) var(--size-60) var(--size-60);
  grid-template-areas:
    'audio-toggle'
    '...'
    'subtitle'
    'title'
    '...'
    'exit-hint';
  grid-template-rows: auto 1fr auto auto 1fr auto;
  gap: var(--size-30);
  text-align: center;
  justify-items: center;
  overflow: hidden;

  [data-context-late-night='true'] & {
    --gradient: var(--gradient-brown);
  }

  [data-context-late-night='false'] & {
    --gradient: var(--gradient-gold);
  }

  & > * {
    min-width: 0;
  }

  & > .shadowLayout {
    position: absolute;
    margin: auto;
    right: 0;
    top: 0;
  }

  & > .title {
    grid-area: title;
    max-width: 632px;
    margin-top: var(--size-48);
    margin-bottom: var(--size-48);

    @media (--viewport-md) {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  & > .subtitle {
    grid-area: subtitle;
  }

  & > .audioToggleLayout {
    grid-area: audio-toggle;
    z-index: 2;
  }

  & > .exitHint {
    grid-area: exit-hint;
  }

  & > .visual {
    z-index: -1;
    grid-column: 1;
    grid-row: 4;
    width: 100%;
    height: 100%;

    @media (--viewport-md) {
      grid-row: 2 / span 4;
    }
  }

  & > .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
  }

  & > .closeOverlay {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}

.close {
  display: flex;
  padding: var(--size-40);

  & > .iconLayout {
    width: var(--size-30);
    height: var(--size-30);
  }
}

.iconLayout {
  display: block;
}

.title {
  font-size: var(--font-size-45);
  font-size: var(--font-size-45-56);
  font-family: var(--font-family-heading);
  letter-spacing: var(--letter-spacing-heading);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-uppercase-heading);
  text-transform: uppercase;
  text-wrap: balance;
}

.exitHint {
  text-transform: uppercase;
  font-size: var(--font-size-12);

  &.touch {
    @media not (pointer: coarse) {
      display: none;
    }
  }

  &.pointer {
    @media (pointer: coarse) {
      display: none;
    }
  }
}

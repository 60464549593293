.component {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-white);
  backdrop-filter: blur(25px);
  border-radius: var(--border-radius-full);
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.0025);
  position: relative;
  z-index: 0;

  & > .iconLayout {
    width: calc(17px * 0.75);
    height: calc(22px * 0.75);
    transform: translateX(15%);

    @media (--viewport-md) {
      width: 17px;
      height: 22px;
    }
  }
}

@value _albumArtMaxSize: 215px;
@value _iconSize: 80px;
@value _albumArtSize: 175px;
@value _gap: 20px;

:export {
  albumArtMaxSize: _albumArtMaxSize;
  albumArtSize: _albumArtSize;
  iconSize: _iconSize;
  gap: _gap;
}

.component {
  --icon-size: _iconSize;
  --album-art-size: _albumArtSize;
  --album-art-max-size: _albumArtMaxSize;
  --container-max-width: min(350px, calc(100vw / 1.35));
  --middle-column-size: calc(var(--container-max-width) - var(--icon-size));
  --shadow-hue: 0.1;

  @media (--viewport-md) {
    --container-max-width: 650px;
    --middle-column-size: calc(var(--container-max-width) - var(--icon-size) - var(--album-art-max-size));
  }

  z-index: 0;
  display: grid;
  position: relative;
  pointer-events: none;
  filter: drop-shadow(0 0 30px rgba(0, 0, 0, var(--shadow-hue)));
  transition: filter var(--motion-duration-150) var(--motion-ease-in-out);
  grid-template-columns: var(--icon-size) var(--middle-column-size) calc(var(--icon-size) / 2);
  grid-template-rows: var(--icon-size) 1fr;
  grid-template-areas:
    "icon   text    ..."
    "icon   content ...";

  @media (--viewport-md) {
    grid-template-columns: var(--icon-size) var(--middle-column-size) var(--album-art-max-size);
    grid-template-rows: var(--icon-size) 1fr;
    grid-template-areas:
      "icon  text     media"
      "icon  content  media";
  }

  &.isHovering {
    --shadow-hue: 0.2;
  }

  &.isOpen {
    --shadow-hue: 0.3;
  }

  & > .backgroundLayout {
    grid-area: 1 / 1 / -1 / -1;
    z-index: -1;
  }

  & > .iconLayout {
    grid-area: icon;
    position: static;
    align-self: start;
    pointer-events: auto;
    height: var(--icon-size);
    width: var(--icon-size);
    z-index: 1;
  }

  & > .textLayout {
    grid-area: text;
    max-width: 30ch;

    @media (--viewport-md) {
      width: var(--middle-column-size);
    }
  }

  & > .contentLayout {
    grid-area: content;
    pointer-events: auto;
    height: 100%;

    @media (--viewport-md) {
      width: var(--middle-column-size);
    }
  }

  & > .mediaLayout {
    grid-area: media;
  }
}

.componentAnchor {
  display: flex;
  position: relative;

  & > .referenceElement {
    min-height: _iconSize;
  }
}

.referenceElement {
  position: relative;
}

.isOpen,
.isHovering {
  & > .iconLayout {
    --pseudo-width: calc(100% - var(--album-art-max-size));
  }
}

.componentIcon {
  --pseudo-width: _iconSize;

  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;

  &:focus,
  &:active {
    outline-color: var(--primary-color-accent);
  }

  &::before {
    content: '';
    position: absolute;
    width: var(--pseudo-width);
    height: _iconSize;
    right: 0;
    left: 0;
    top: 0;
  }
}

.componentText {
  font-size: var(--font-size-18);
  font-family: var(--font-family-heading);
  line-height: var(--line-height-heading);
  font-weight: var(--font-weight-400);
  color: var(--primary-color);
  font-style: italic;
  display: inline-flex;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.componentContent {
  position: relative;
  pointer-events: none;
  user-select: none;
  overflow: hidden;

  @media (--viewport-md) {
    padding-right: _gap;
  }

  &.isVisible {
    pointer-events: auto;
    user-select: auto;
  }

  & > .contentInner {
    height: max-content;
    width: 100%;
  }
}

.contentInner {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 0;
  gap: _gap;

  & > * {
    width: 100%;
  }

  & > .buttonLayout {
    z-index: 2;
  }
}

.contentInnerText {
  line-height: 1.35;
  font-size: var(--font-size-16);
  font-family: var(--font-family-base);
  letter-spacing: var(--letter-spacing-paragraph);
  font-weight: var(--font-weight-400);
  color: var(--primary-color);
}

.componentMedia {
  display: inline-grid;
  position: relative;
  overflow: hidden;

  @media (--viewport-md) {
    padding: var(--size-20);
  }

  & > .mediaElementLayout {
    margin-top: var(--size-20);
    border-radius: 0.5rem;
    width: var(--album-art-size);

    @media (--viewport-md) {
      padding: 0;
      margin: 0;
    }
  }
}

.componentBackground {
  display: flex;
  background: var(--primary-background-color);
  border-radius: 1rem;
}

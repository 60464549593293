.component {
  cursor: default;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.componentWorkWithSoul {
  text-transform: lowercase;
  font-size: var(--font-size-25);
  font-size: var(--font-size-25-30);
  font-family: var(--font-family-heading);
  color: var(--color-white);
  position: relative;
  user-select: none;
  line-height: 1;
  z-index: 0;

  & > .workWithText {
    margin-bottom: var(--size-10);
  }

  & > .visual {
    width: min(75vw, 300px);

    @media (--viewport-md) {
      width: 300px;
    }
  }
}

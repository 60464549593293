.componentBase {
  overflow: auto; /* Disable margin collapse */
  color: var(--primary-color);
}

.componentMainContent,
.componentCheckmarks {
  & > * {
    margin-bottom: var(--size-18);
  }

  & > .headingLayout {
    margin-bottom: var(--size-18);
    margin-top: var(--size-64);
  }

  & > .subheadingLayout {
    margin-bottom: var(--size-24);
    margin-top: var(--size-64);
  }

  & > :first-child {
    margin-top: 0;
  }

  & > :last-child {
    margin-bottom: 0;
  }
}

.list {
  display: flex;
  flex-direction: column;
  list-style-position: outside;
}

.listOrdered {
  display: flex;
  list-style-type: decimal;
  margin-left: var(--size-24);

  & > .listOrdered {
    list-style-type: lower-alpha;
  }
}

.listItem {
  position: relative;
  font-size: var(--font-size-16);
  font-size: var(--font-size-16-18);
  font-weight: var(--font-weight-350);

  & > .listOrdered,
  & > .listUnordered {
    margin-left: var(--size-18);
  }

  & > .listOrdered {
    list-style-type: lower-alpha;
  }
}

.listUnordered {
  display: flex;
  flex-direction: column;
  list-style-type: none;

  & > .listItem::before {
    &:has(.listUnordered) {
      content: '';
    }

    content: '•';
    line-height: 1.2;
    margin-top: 0.15em;
    margin-right: 1em;
  }
}

.listChecklist {
  --svg: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNyIgaGVpZ2h0PSIxMiIgZmlsbD0ibm9uZSI+PHBhdGggc3Ryb2tlPSIjMUExOTE5IiBzdHJva2UtbGluZWNhcD0ic3F1YXJlIiBkPSJNMTYgMSA2LjIyNSAxMSAxIDUuOCIvPjwvc3ZnPgo=);

  display: inline-flex;
  flex-direction: column;
  list-style-type: none;
  position: relative;

  & > .listItem {
    margin-left: 35px;
  }

  & > .listItem::before {
    content: '';
    mask: var(--svg);
    display: inline-block;
    background-color: var(--primary-color);
    margin: 0 var(--size-24) 0 var(--size-6);
    position: absolute;
    top: var(--size-8);
    left: -38px;
    height: var(--size-12);
    width: 17px;
  }

  & > .listItem:not(:last-child) {
    margin-bottom: var(--size-10);
  }
}

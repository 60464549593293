.localSectionSand {
  position: relative;
  z-index: 0;

  & > .shadowCastingLayout {
    position: absolute;
    z-index: 0;
    inset: 0;
    right: 0;
    top: 0;
  }

  & > *:not(.shadowCastingLayout) {
    position: relative;
    z-index: 1;
  }
}

.componentVideoGroup {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--size-32-100);

  @media (--viewport-md) {
    grid-template-columns: 2fr 3fr;
  }
}

.textContentBlock {
  display: flex;
  flex-direction: column;
  gap: var(--size-16);
}

.videoRepeaterGrid {
  display: grid;
  gap: var(--size-30);
}

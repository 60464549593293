.component {
  display: grid;
  gap: var(--size-32-100);
  color: var(--primary-color);
  background-color: var(--primary-background-color);
  grid-template-rows: max-content 1fr;
  grid-template-columns: 1fr;
  padding-top: var(--size-40);
  text-align: center;
  position: relative;
  z-index: 0;

  & > .backgroundTitleLayout {
    z-index: 1;
  }

  & > .subGridLayout {
    z-index: 2;
  }
}

.componentSubGrid {
  z-index: 0;
  display: grid;
  overflow: hidden;
  position: relative;
  grid-template-columns: auto min(calc(100vw - (var(--size-32) * 2)), var(--container-max-width-xl)) auto;
  grid-template-rows: 200px max-content 200px;
  grid-template-areas:
    '...  ...      ...'
    '...  content  ...'
    '...  ...      ...';

  & > .backgroundLayout {
    position: absolute;
    z-index: -1;
    inset: 0;
  }

  & > .contentLayout {
    grid-area: content;
  }
}

.componentBackground {
  & > .transformWrapper {
    height: 100%;
  }
}

.transformWrapper {
  transform:
    translateY(calc(var(--scroll-y) * -0.15px))
    scale(calc(1.2 - (var(--scroll-value) / 10)));

  & > .imageLayout {
    width: 100%;
    height: 100%;
  }
}

.backgroundElement {
  background-color: var(--primary-background-color);
}

.componentContent {
  background-color: var(--primary-background-color);
  padding: var(--size-30);
  padding: var(--size-30-120);
}

.innerContentBlock {
  display: grid;
  text-align: left;
  row-gap: var(--size-18);
  column-gap: var(--size-10);
  grid-template-columns: 1fr;
  grid-template-rows: 1fr var(--size-10) auto var(--size-10) auto;
  grid-template-areas:
    'text'
    '...'
    'growth'
    '...'
    'button';

  @media (--viewport-lg) {
    grid-template-columns: minmax(0, 350px) var(--size-32) auto;
    grid-template-rows: 1fr var(--size-32) 1fr;
    row-gap: var(--size-14);
    grid-template-areas:
      'text   text  text'
      '...    ...   ...'
      'growth ...    button';
  }

  & > .growthExplanationLayout {
    grid-area: text;
  }

  & > .drive {
    grid-area: growth;
  }

  & > .buttonElement {
    justify-self: end;
    grid-area: button;
    width: 100%;

    @media (--viewport-md) {
      width: auto;
    }
  }
}

.buttonElement {
  display: flex;
  align-items: flex-start;

  & > * {
    width: 100%;

    @media (--viewport-md) {
      width: auto;
    }
  }
}

.followLineBreaks {
  white-space: break-spaces;
}

.page {
  --lotus-width: 56px;
  --line-width: 1px;

  position: relative;
  z-index: 0;
}

.timelineContainer {
  display: grid;

  & > .itemLayout {
    margin: 0 calc(var(--lotus-width) / 2);

    @media (--viewport-md) {
      margin: 0;
    }
  }
}

.componentItem {
  display: grid;
  grid-auto-flow: dense;
  grid-template-columns: var(--line-width) 1fr;
  grid-template-rows: var(--size-40) 1fr auto var(--size-40);
  grid-template-areas:
    'line   ...'
    'line   image'
    'line   content'
    'line   ...';

  @media (--viewport-md) {
    grid-template-columns: 1fr var(--line-width) 1fr;
    grid-template-rows: var(--size-80) 1fr var(--size-80);
    grid-template-areas:
      '...    line  ...'
      'image  line  content'
      '...    line  ...';
  }

  & > .lineLayout {
    grid-area: line;
    width: var(--line-width);
  }

  & > .imageContainer {
    grid-area: image;
  }

  & > .textContainer {
    grid-area: content;
  }
}

.isReversed {
  display: grid;

  & > .textContainer {
    @media (--viewport-md) {
      grid-column: 1;
    }
  }

  & > .imageContainer {
    @media (--viewport-md) {
      grid-column: 3;
    }
  }
}

.componentLine {
  background-color: var(--primary-color);
}

.lotusContainer {
  display: flex;

  @media (--viewport-md) {
    justify-content: center;
  }

  & > .cherryBlossomLayout {
    transform: translateY(1px) translateX(-3px);

    @media (--viewport-md) {
      transform: translateY(1px) translateX(-1px);
    }
  }
}

.imageContainer {
  display: inline-flex;
  align-items: center;

  @media (--viewport-md) {
    align-items: flex-start;
  }
}

.textContainer {
  display: flex;
  gap: var(--size-10);
  flex-direction: column;
  padding: var(--size-30) 0 var(--size-30) var(--size-24);

  @media (--viewport-md) {
    padding: 0 calc(var(--size-32-100) * 1.2) var(--size-32-100) calc(var(--size-32-100) * 0.8);
  }

  & > .date {
    margin-bottom: var(--size-10);
  }
}

.component {
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: var(--primary-color);
  background-color: var(--primary-background-color);
  aspect-ratio: 2 / 1;
  position: relative;
  padding: var(--size-32) var(--size-64);

  @media (--viewport-md) {
    padding: var(--size-40) var(--size-24);
  }

  & > .titleLayout {
    margin-bottom: var(--size-18);
    flex: 1 auto;
  }

  & > .descriptionLayout {
    margin-bottom: var(--size-18);
  }

  & > .type {
    margin-bottom: var(--size-10);
  }

  & > .buttonLayout {
    position: static;
  }
}

.type {
  text-transform: uppercase;
  font-family: var(--font-family-heading);
  font-size: var(--font-size-12);
  color: var(--primary-color-accent);
}

.title {
  font-family: var(--font-family-heading);
  line-height: var(--line-height-heading);
  font-size: var(--font-size-30);
  font-size: var(--font-size-30-45);
  text-align: center;
}

.description {
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-400);
}

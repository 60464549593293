.component {
  position: relative;
  overflow: hidden;
  z-index: 0;

  & > .blurLayout {
    position: absolute;
    height: 100%;
    width: 100%;
    inset: 0;
    z-index: 1;
  }
}

.blurLayout {
  object-fit: cover;
  user-select: none;
  -webkit-mask-image: linear-gradient(to bottom, #0000 40%, #000 80%);
  mask-image: linear-gradient(to bottom, #0000 40%, #000 80%);

  &:last-of-type {
    transform: scale(1.2);
  }
}

.componentCover {
  object-fit: cover;
}

.componentBase {
  display: inline-block;
  vertical-align: middle;
  border-radius: 50%;
  animation: spin 700ms infinite linear;
}

.componentSmall {
  width: 1em !important;
  height: 1em !important;
  border-top: var(--size-2) solid currentColor;
  border-right: var(--size-2) solid currentColor;
  border-bottom: var(--size-2) solid currentColor;
}

.componentLarge {
  width: 3em !important;
  height: 3em !important;
  border-top: var(--size-2) solid currentColor;
  border-right: var(--size-2) solid currentColor;
  border-bottom: var(--size-2) solid currentColor;
}

@keyframes spin {
  0% { transform: rotate(0turn); }
  100% { transform: rotate(1turn); }
}

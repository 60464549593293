.componentBase {
  color: inherit;
}

.componentUnderline {
  text-decoration: underline;
  text-underline-offset: 3px;
  text-decoration-color: var(--primary-color-accent);
  transition: text-decoration-color var(--motion-duration-150) var(--motion-ease-in-out);

  &:hover {
    text-decoration-color: var(--primary-background-color-accent);
  }
}

.componentUnderlineOnHover {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.component {
  &[data-style-context="white"] {
    background-color: var(--color-white);
  }

  &[data-style-context="black"] {
    background-color: var(--color-dark-800);
  }

  position: relative;
  z-index: 0;

  & > .filtersLayout {
    position: sticky;
    z-index: 1;
    top: 0;

    @media (--viewport-md) {
      min-height: var(--size-70);
    }
  }
}

.filtersLayout {
  transform: translateY(0);
  transition: transform var(--motion-duration-350) var(--motion-ease-in-out);

  &.isSlidDown {
    transform: translateY(var(--size-70));
  }
}

.component {
  & > .canvas {
    width: 100%;
    height: 100%;
  }
}

.canvas {
  object-fit: cover;
}

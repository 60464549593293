.title {
  font-family: var(--font-family-heading);
  letter-spacing: var(--letter-spacing-heading);
  font-weight: var(--font-weight-400);
  font-size: var(--font-size-22);
}

.label,
.chipLabel {
  font-weight: var(--font-weight-400);
}

.componentRadioGroup,
.componentCheckboxGroup,
.componentCheckboxChipsGroup,
.componentSelect,
.componentText {
  & > .title {
    margin-bottom: var(--size-20);
  }
}

.options,
.optionChips {
  display: flex;
  flex-direction: column;
  gap: var(--size-20);
}

.radioField,
.checkboxField {
  display: flex;
  gap: var(--size-20);

  & > .radioInput,
  & > .checkboxInput {
    width: var(--size-24);
    height: var(--size-24);
    flex-shrink: 0;
  }
}

.radioInput,
.checkboxInput {
  display: grid;
  place-content: center;
  border: 1px solid;
  appearance: none;
  cursor: pointer;

  &::before {
    content: "";
    width: calc(var(--size-24) - 6px);
    height: calc(var(--size-24) - 6px);
    box-shadow: inset 1em 1em 1em;
    transform: scale(0);
  }

  &:checked::before {
    transform: scale(1);
  }
}

.radioInput {
  border-radius: var(--border-radius-full);

  &::before {
    border-radius: var(--border-radius-full);
  }
}

.componentRange {
  font-weight: var(--font-weight-400);
}

.componentRangeField {
  --dot-size: var(--size-18);
  --half-dot-size: calc(var(--dot-size) / 2);

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto;
  grid-template-areas:
    "labels"
    "sliders"
    "indicators";

  & > .rangeLabelsLayout {
    grid-area: labels;
  }

  & > .rangeSpanLayout,
  & > .rangeTrackLayout,
  & > .rangeInputsLayout {
    grid-area: sliders;
  }

  & > .rangeIndicatorsLayout {
    grid-area: indicators;
  }

  & > :not(.rangeInputsLayout) {
    margin-left: var(--half-dot-size);
    margin-right: var(--half-dot-size);
  }

  & > .rangeTrackLayout {
    align-self: center;
  }
}

.componentRangeInputs {
  display: grid;
  grid-template-columns: 1fr;

  & > * {
    grid-column-start: 1;
    grid-row-start: 1;
    width: 100%;
  }
}

.componentRangeInput {
  pointer-events: none;
  outline: none;
  -webkit-appearance: none;
  appearance: none;
  -webkit-tap-highlight-color: transparent;

  &::-webkit-slider-thumb {
    background-color: var(--primary-color);
    border: none;
    border-radius: 50%;
    cursor: pointer;
    height: var(--size-18);
    width: var(--size-18);
    pointer-events: all;
    position: relative;
    -webkit-appearance: none;
    appearance: none;
    -webkit-tap-highlight-color: transparent;
  }

  &::-moz-range-thumb {
    background-color: var(--primary-color);
    border: none;
    border-radius: 50%;
    cursor: pointer;
    height: var(--size-18);
    width: var(--size-18);
    pointer-events: all;
    position: relative;
    -webkit-appearance: none;
    appearance: none;
    -webkit-tap-highlight-color: transparent;
  }
}

.componentRangeSpan {
  position: relative;
  display: flex;
  align-items: center;

  & > .rangeSpanLineLayout {
    position: relative;
  }
}

.rangeSpanLine,
.componentRangeTrack {
  height: 1px !important;
}

.rangeSpanLine {
  background-color: var(--primary-color);
}

.componentRangeTrack {
  background-color: var(--primary-color--40);
}

.componentRangeLabel {
  color: var(--primary-color--40);

  &.start {
    transform: translateX(-50%);
  }

  &.end {
    transform: translateX(+50%);
  }
}

.componentRangeLabels {
  display: flex;
  justify-content: space-between;
  user-select: none;
}

.componentRangeIndicators {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  user-select: none;

  & > * {
    grid-column-start: 1;
    grid-row-start: 1;
    position: relative;
    width: fit-content;
  }
}

.componentRangeIndicator {
  transform: translateX(-50%);
  transform-origin: center;
}

.textField {
  border-bottom: 1px solid var(--primary-color);
  font-weight: var(--font-weight-400);
  letter-spacing: 0.05em;

  & > .textInput {
    width: 100%;
    margin: auto;
  }
}

.textInput {
  padding-bottom: var(--size-10);
}

.optionChips {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--size-10);

  & > .checkboxChipField {
    width: var(--size-40);
  }
}

.checkboxChipField {
  position: relative;
  z-index: 0;
  display: grid;
  grid-template-areas: "stack";
  aspect-ratio: 1;

  & > * {
    grid-area: stack;
  }

  & > .chipLabel {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
  }
}

.checkboxChipInput {
  visibility: hidden;
}

.chipLabel {
  --color: inherit;
  --border-color: currentColor;
  --background-color: var(--primary-background-color);

  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--border-color);
  color: var(--color);
  background-color: var(--background-color);
  transition: var(--motion-duration-150) var(--motion-ease-in-out);
  transition-property: border-color, color, background-color;

  *:checked + & {
    --color: var(--primary-background-color);
    --border-color: transparent;
    --background-color: var(--primary-color);
  }

  /* stylelint-disable kaliber/selector-policy, kaliber/layout-related-properties */
  *:focus-visible + & {
    outline: 1px solid var(--primary-color-accent);
    outline-offset: 1px;
  }
  /* stylelint-enable kaliber/selector-policy, kaliber/layout-related-properties */
}

.componentTypeSwitch {
  --logo: var(--color-white);
  --accent: var(--color-white);

  --desktop-menu-display-value: none;
  --mobile-menu-display-value: grid;

  height: var(--size-70);
  background-color: var(--color-black);
  transform: translateY(-100%);
  transition:
    transform var(--motion-duration-350) var(--motion-ease-in-out),
    background-color var(--motion-duration-350) var(--motion-ease),
    color var(--motion-duration-200) var(--motion-ease);

  &.isOpen {
    transform: translateY(0);
  }

  @media (--viewport-xl) {
    --desktop-menu-display-value: grid;
    --mobile-menu-display-value: none;
  }

  & > .desktopMenuLayout {
    margin: var(--size-20);
    height: calc(70px - (2 * var(--size-20)));
  }
}

.component {
  &[data-style-context="sand"],
  &[data-style-context="clay"],
  &[data-style-context="white"] {
    background: linear-gradient(98deg, #7d653c 0%, #dabc81 100%);
  }

  &[data-style-context="gray"],
  &[data-style-context="black"] {
    background: linear-gradient(98deg, #2e2929 0%, #553d30 100%);
  }

  --header-size: var(--size-100);

  display: grid;
  grid-template-rows:
    minmax(var(--header-size), auto)
    auto;
  grid-template-areas:
    'header'
    'jobs';
  color: var(--primary-color);
  position: relative;
  padding-bottom: var(--header-size);
  overflow: hidden;

  & > .notifications {
    grid-area: header;
    align-self: center;
    justify-self: center;
  }

  & > .jobsLayout {
    grid-area: jobs;
  }

  & > .shadowCastingLayout {
    position: absolute;
    width: min(1080px, 100vw);
    max-height: none;
    max-width: none;
    right: 0;
    top: 0;
  }
}

.componentList {
  display: grid;
  gap: var(--size-64);
  padding-bottom: var(--size-80);
}

.componentJobs {
  display: flex;
  flex-direction: column;

  & > .showMoreJobsButtonLayout {
    align-self: center;
    margin-top: var(--size-16);
  }
}

.notifications {
  padding: var(--container-padding);
}

.componentNoResultsMessage,
.componentLoading {
  color: var(--primary-color);
}

.componentNoResultsMessage,
.componentErrorMessage {
  font-family: var(--font-family-heading);
  font-weight: var(--font-weight-400);
  font-size: var(--font-size-25);
  font-size: var(--font-size-25-30);
  line-height: var(--line-height-small-heading);
  text-align: center;
  padding: var(--size-40) 0;
}

.componentLoading {
  display: flex;
  justify-content: center;
  padding: var(--size-20);
}

.page {
  position: relative;
  z-index: 0;

  & > .title {
    margin-top: var(--size-40);
    margin-bottom: var(--size-18);
  }
}

.title {
  font-size: var(--font-size-32-40);
  font-weight: bold;
}

.page {
  & > .heroLayout {
    height: 100%;
    max-height: 100lvh;
  }
}

.heroLayout {
  & > * {
    max-height: 100lvh;
  }
}

.component {
  display: flex;
  flex-direction: column;
  gap: var(--size-20);
}

._rootModal {
  --padding: var(--size-64);

  display: flex;
  align-items: center;
  justify-content: center;

  & > .modalLayout {
    width: min(calc(100vw - var(--padding)), 380px);
    height: min(calc(100vh - var(--padding)), 540px);
  }
}

.overlay {
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px);
}

.componentModal {
  --logo-height: 67px;

  display: grid;
  gap: var(--size-30);
  padding: var(--size-50);
  grid-template-columns: 1fr;
  transform: translateY(-5dvh);
  grid-template-rows: var(--logo-height) repeat(3, max-content) auto;
  background-color: var(--primary-background-color);
  scrollbar-width: none;
  position: relative;

  @media screen and (max-height: 600px) {
    overflow-y: scroll;
  }

  &::-webkit-scrollbar {
    @media screen and (max-height: 600px) {
      display: none;
    }
  }

  & > .closeButton {
    position: absolute;
    right: var(--size-30);
    top: var(--size-30);
  }

  & > .headingLayout {
    max-width: 20ch;
    justify-self: center;
  }

  & > .buttonLayout {
    align-self: end;
  }
}

.componentLogo {
  display: flex;
  justify-content: center;
}

.componentHeading {
  text-align: center;
}

.componentCountries,
.componentLanguages {
  display: flex;
  flex-direction: column;
}

@value _cardHeightSm: 400px;
@value _cardWidthSm: 250px;

@value _cardHeightMd: 450px;
@value _cardWidthMd: 300px;

:export {
  cardHeightSm: _cardHeightSm;
  cardWidthSm: _cardWidthSm;
  cardHeightMd: _cardHeightMd;
  cardWidthMd: _cardWidthMd;
}

.component {
  --slider-wrapper-height: _cardHeightSm;
  --slider-wrapper-width: _cardWidthSm;

  @media (--viewport-md) {
    --slider-wrapper-height: _cardHeightMd;
    --slider-wrapper-width: _cardWidthMd;
  }

  --height: calc(var(--slider-wrapper-height) * 1.25);

  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 1fr;
  grid-template-rows: var(--height) 1fr auto;
  gap: var(--size-10);
  overflow: visible;
  background-color: var(--primary-background-color);
  color: var(--primary-color);
  position: relative;
  z-index: 0;

  & > .textContainer {
    @media (--viewport-md) {
      margin-top: var(--size-12);
    }
  }

  & > .sliderLayout {
    height: var(--height);
  }

  & > .dashContainerElement {
    margin-top: var(--size-10);
  }
}

.textContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: var(--container-padding);
  gap: var(--size-20);
}

.dashContainerElement {
  display: flex;
  justify-content: center;
  gap: var(--size-10);

  & > * {
    width: var(--size-50);
  }
}

.componentDash {
  display: flex;
  position: relative;

  & > .dashElement {
    position: static;
    margin: var(--size-10) 0;
    width: 100%;
    height: 1px;
  }
}

.dashElement {
  transition: background-color var(--motion-duration-350) var(--motion-ease);
  background-color: var(--primary-color--15);
  outline: 1px solid var(--dash-accent);
  outline-offset: 2px;
  position: relative;

  &.isActive {
    background-color: var(--primary-color-accent);
  }

  &::after {
    content: '';
    cursor: pointer;
    position: absolute;
    inset: 0;
  }
}

.componentSlide {
  display: grid;
  position: relative;
  transform-origin: bottom center;
  touch-action: none;
  user-select: none;
  z-index: 0;

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.65), transparent var(--gradient-transition-value, 0%));
  }

  & > .cursor {
    z-index: 2;
    grid-area: 1 / -1;
    position: static;
  }

  & > .text {
    z-index: 1;
    grid-area: 1 / -1;
  }

  & > .imageWrapper {
    width: 100%;
    height: 100%;
    grid-area: 1 / -1;
    z-index: 0;
  }
}

.cursor {
  display: flex;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    inset: 0;
  }

  & > .cursorLayout {
    margin: var(--size-20);
    height: var(--size-50);
    width: var(--size-50);
    align-self: end;
  }
}

.text {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  color: var(--color-white); /* Deliberately always white; don't use primary here */
  gap: var(--size-10);
  text-align: center;
  padding: 3rem;

  & > * {
    width: auto;
  }

  & > *:not(:first-child):not(.buttonLayout) {
    font-size: var(--font-size-12);
    font-size: var(--font-size-12-14);
  }

  & > *:first-child:not(.buttonLayout) {
    font-size: var(--font-size-18);
    font-size: var(--font-size-18-22);
  }
}

.card {
  touch-action: none;
  transform-origin: center 120%;
  cursor: grab;

  &.isDragging {
    cursor: grabbing;
  }

  & > * {
    width: 100%;
    height: 100%;
  }
}

.componentSlider {
  --chevron-offset: calc(100vw - var(--container-max-width-xl));

  position: relative;
  overflow: visible;
  z-index: 0;

  & > .card {
    position: absolute;
    width: var(--slider-wrapper-width);
    height: var(--slider-wrapper-height);
  }

  & > .arrowLeftLayout {
    left: calc(var(--chevron-offset) / 3);
  }

  & > .arrowRightLayout {
    right: calc(var(--chevron-offset) / 3);
  }

  & > .arrowLeftLayout,
  & > .arrowRightLayout {
    position: absolute;
    z-index: 1;
    top: 50%;
  }
}

.imageWrapper {
  position: relative;
  z-index: 0;
  overflow: hidden;

  &::after {
    content: '';
    display: block;
    position: absolute;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 2;
  }

  & > .imageLayout,
  & > .blurredOverlay {
    width: 100%;
    height: 100%;
  }

  & > .blurredOverlay {
    position: absolute;
    inset: 0;
    z-index: 1;
  }
}

.imageLayout {
  & > * {
    min-width: 100%;
    min-height: 100%;
  }
}

.blurredOverlay {
  & > .blurredOverlayLayout {
    min-width: 100%;
    min-height: 100%;
  }
}

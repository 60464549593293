.componentListItem {
  display: grid;
  text-align: left;
  column-gap: var(--size-32);
  row-gap: var(--size-6);
  grid-template-columns: 1fr auto;
  grid-template-areas:
    "title  button"
    "text   button";
  position: relative;

  & > .titleLayout {
    grid-area: title;
    justify-self: start;
  }

  & > .detailsLayout {
    grid-area: text;
    justify-self: start;
  }

  & > .buttonLayout {
    grid-area: button;
    position: static;
  }
}

.componentDetails {
  display: flex;
  flex-direction: column;
  gap: var(--size-8);
}

.internalDetails {
  display: flex;
  gap: var(--size-8);
}

.componentMetaDataLabel {
  border-radius: var(--border-radius-full);
  padding: var(--size-6) var(--size-10);
  background: var(--primary-color--15);
  font-size: var(--font-size-12);
  color: var(--primary-color);
  line-height: 1;
}

.componentCardRelated,
.componentCardHighlighted,
.componentCardRelatedHoliday {
  --shadow-opacity: 0;

  z-index: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--size-80) var(--size-40) var(--size-40) var(--size-40);
  text-align: center;
  box-shadow: var(--shadow-card);
  border: 1px solid var(--border-color);
  transition: border-color var(--motion-duration-150) var(--motion-ease-in-out);

  &:hover {
    --shadow-opacity: 0.5;
  }

  & > .titleLayout {
    margin-bottom: var(--size-18);
  }

  & > .buttonLayout {
    margin-top: auto;
    align-self: center;
    position: static;
  }

  & > .shadowCastingContainer {
    z-index: -1;
    position: absolute;
    right: 0;
    top: 0;
  }
}

.componentCardRelatedHoliday {
  --border-color: var(--primary-color--15);
  --shadow-card: var(--shadow-card-black);

  &:hover,
  &:focus-visible {
    --border-color: var(--primary-color);
  }
}

.shadowCastingContainer {
  opacity: var(--shadow-opacity);
  transition: opacity var(--motion-duration-350) var(--motion-ease);

  & > .shadowCastingLayout {
    width: 100%;
    height: 100%;
  }
}

.componentCardHighlighted {
  background-color: var(--color-white);
}

.componentCardRelated {
  color: var(--primary-color);
  background-color: var(--primary-background-color);
}

.component {
  display: flex;
  flex-direction: column;
}

.componentItem {
  display: grid;
  grid-template-areas: 'stack';
  box-sizing: border-box;
  text-align: left;
  border-bottom: 1px solid var(--color-black--30);
  vertical-align: unset;
  position: relative;
  z-index: 0;
  user-select: text;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    cursor: pointer;
  }

  & > .backdropLayout {
    grid-area: stack;
    width: 100%;
    height: 100%;
  }

  & > .questionAndAnswer {
    grid-area: stack;
    z-index: 1;
  }
}

.buttonIsOpen {
  &:hover {
    cursor: unset;
  }
}

.questionAndAnswer {
  display: flex;
  flex-direction: column;
  gap: var(--size-20);
  padding: var(--size-40) var(--size-20);

  @media (--viewport-md) {
    padding: var(--size-60) var(--size-20);
  }
}

.componentAnswer {
  opacity: 0;
  animation: fadeUp var(--motion-duration-400) ease forwards;
}

.componentQuestion {
  display: flex;
  gap: var(--size-20);
  justify-content: space-between;
  align-items: center;

  @media (--viewport-md) {
    gap: var(--size-40);
  }
}

.iconWrapper {
  display: inline-flex;
  transform: rotate(0turn);
  transition: transform var(--motion-duration-500) ease-in-out, scale var(--motion-duration-200) ease-in-out;
  aspect-ratio: 1;

  &:hover {
    scale: 1.1;
  }

  & > .iconLayout {
    width: 35px;

    @media (--viewport-md) {
      width: 48px;
    }
  }
}

.iconIsOpen {
  transform: rotate(-0.5turn);
}

.componentBackdrop {
  display: flex;
  box-sizing: content-box;
  transform: translate(0, 0) scaleY(0);
  transform-origin: bottom;
  transition: transform var(--motion-duration-800) ease;
  background: var(--color-dark-800);

  @media (--viewport-md) {
    padding: var(--size-6) var(--size-30);
    transform: translate(calc(-1 * var(--size-30)), 0) scaleY(0);
  }
}

.backdropShow {
  transform: translate(0, var(--size-10)) scaleY(1.06);
  transition: transform var(--motion-duration-400) ease;

  @media (--viewport-md) {
    transform: translate(calc(-1 * var(--size-30)), var(--size-6)) scaleY(1.05);
  }
}

@keyframes fadeUp {
  from {
    opacity: 0;
    transform: translateY(2rem);
  }

  to {
    opacity: 1;
    transform: translate(0);
  }
}

.component {
  @media (--viewport-md) {
    padding-bottom: var(--size-24);
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > .container {
    flex: 1 1 auto;
  }
}

.container {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-top: var(--size-120);
  padding-bottom: var(--size-40);

  @media (--viewport-md) {
    padding-top: var(--size-170);
    padding-bottom: var(--size-80);
  }

  & > .italicSubHeading {
    margin-bottom: var(--size-18);
  }

  & > .inputContainer {
    flex: 1 1 auto;
    min-width: 50vw;
  }
}

.italicSubHeading {
  user-select: none;
  pointer-events: none;
  letter-spacing: var(--letter-spacing-paragraph);
  font-size: var(--font-size-16);
  font-style: italic;
  font-weight: var(--font-weight-400);
  color: var(--color-gray-400);

  @media (--viewport-md) {
    font-size: var(--font-size-18);
  }
}

.inputContainer {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;

  & > .searchTextInputLayout,
  & > .tickerLayout {
    grid-row-start: 1;
    grid-column-start: 1;
    min-width: 0;
  }
}

.componentSearchTextInput {
  appearance: none;
  font-size: var(--font-size-34);
  font-family: var(--font-family-heading);
  caret-color: var(--primary-color-accent);
  text-align: center;
  border-bottom: 1px solid var(--primary-color--15);
  color: var(--primary-color);

  @media (--viewport-md) {
    font-size: var(--font-size-45);
    font-size: var(--font-size-45-65);
  }

  &:focus,
  &:active {
    outline-width: 0;
    outline-color: transparent;
    color: var(--primary-color);
  }

  &::placeholder {
    color: transparent;
  }

  &::selection {
    color: var(--primary-color-accent);
    background-color: var(--primary-color--15);
  }
}

.component {
  display: inline-flex;
  text-transform: uppercase;
  align-items: center;
  gap: var(--size-10);

  & > .iconLayout {
    height: var(--size-24);
    width: var(--size-24);
  }
}

.component {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  background-color: var(--primary-background-color);
  color: var(--primary-color);

  @media (--viewport-md) {
    flex-direction: row;
  }

  & > .ctaImageLayout {
    flex: 1;
  }

  & > .contentLayout {
    flex: 2;
  }
}

.component[data-style-context="holiday"] {
  --primary-background-color: var(--color-black);

  background-image: url(/images/holiday/bg-sparkles-cta-1200@2x.jpg);
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: cover;
}

.componentContent {
  display: flex;
  justify-content: center;
  align-items: center;

  & > .contentWrapper {
    width: 100%;

    @media (--viewport-md) {
      width: 70%;
    }
  }
}

.componentCTAImage {
  overflow: hidden;
  aspect-ratio: 1;

  @media (--viewport-md) {
    aspect-ratio: auto;
  }

  & > * {
    height: 100%;
    width: 100%;
  }
}

.imageLayout {
  & > .image {
    width: 100%;
    height: 100%;
  }
}

.image {
  object-fit: cover;
}

.componentButtons {
  display: flex;
  flex-wrap: wrap;
  gap: var(--size-10);

  @media (--viewport-md) {
    flex-wrap: nowrap;
  }

  & > .ctaButtonLayout {
    width: 100%;

    @media (--viewport-md) {
      width: auto;
    }
  }
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  gap: var(--size-30);
  padding: var(--size-48);

  @media (--viewport-md) {
    padding: var(--size-70) 0;
  }
}

.component {
  color: var(--button-text-color, var(--primary-background-color));
  background-color: var(--button-background-color, var(--primary-color));

  &.isDisabled {
    color: var(--primary-color);
    background-color: var(--primary-color--15);
  }
}

.componentGhostIcon,
.componentGhostIconRounded {
  color: var(--button-text-color);
  border-color: var(--button-text-color);
}

.componentBase,
.componentABase {
  --button-border-color: transparent;

  font-weight: var(--font-weight-400);
  text-transform: uppercase;
  font-size: var(--font-size-12);
  text-align: center;
  letter-spacing: var(--letter-spacing-button);
  border: 1px solid var(--button-border-color);
  transition: var(--motion-duration-150) var(--motion-ease-in-out);
  transition-property: border, color, background-color;

  &:hover {
    background-color: var(--button-hover-background-color);
    border-color: currentColor;
    color: var(--button-hover-text-color, var(--primary-color));
  }

  &:focus {
    outline-color: var(--primary-color-accent);
  }

  &.isDisabled {
    cursor: not-allowed;
  }
}

.componentRoundedIcon,
.componentGhost,
.componentGhostIconBase {
  color: var(--primary-color);
  position: relative;

  &::after {
    content: "";
    position: absolute;
    inset: 0;
  }
}

.componentRoundedIcon {
  &:hover {
    --button-border-color: var(--button-ghost-or-icon-border-color);
  }
}

.componentGhostIconBase {
  --icon-border-color: var(
    --button-ghost-or-icon-border-color,
    var(--primary-color--15)
  );

  &:hover {
    --icon-border-color: var(
      --button-ghost-or-icon-hover-border-color,
      var(--primary-color)
    );
  }
}

.componentRoundedIcon,
.componentGhostIconBase {
  border: none;

  & > .iconInner {
    width: var(--size-48);
    height: var(--size-48);
  }
}

.componentGhost {
  border: 1px solid var(--primary-color--15);
  transition: border var(--motion-duration-200) var(--motion-ease-in-out);

  &:hover {
    border-color: var(--primary-color);
  }
}

.isRounded {
  --border-radius: 50%;
}

.iconInner {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius);
  border: 1px solid var(--icon-border-color);
  transition: border-color var(--motion-duration-200) var(--motion-ease-in-out);
}

.isPrimary {
  border: 1px solid var(--button-border-color);
  background-color: var(--primary-background-color);
}

.isPadded {
  padding: var(--size-14) var(--size-32);
}

.componentRoundedIconSlider {
  padding: var(--size-20);
  color: var(--primary-color);
  backdrop-filter: blur(25px);
  border-radius: 50%;
  display: none;
  position: relative;
  z-index: 0;

  @media (--viewport-xl) {
    display: block;
  }

  &::before {
    content: "";
    z-index: -1;
    position: absolute;
    border-radius: 50%;
    background: var(--primary-background-color);
    transition: opacity var(--motion-duration-350) var(--motion-ease);
    border: 1px solid currentColor;
    opacity: 0.3;
    inset: 0;
  }

  &:hover,
  &:focus-visible {
    &::before {
      opacity: 0.5;
    }
  }
}

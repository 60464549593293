.page {
  z-index: 0;
  position: relative;
  display: grid;
  grid-template-rows: 500px auto;
  background-color: var(--color-sand-200);

  @media (--viewport-md) {
    grid-template-rows: 600px auto;
  }

  @media (--viewport-lg) {
    grid-template-rows: none;
    grid-template-columns:
      minmax(300px, 35vw) auto minmax(var(--size-100), 868px)
      auto;
    gap: 0;
  }

  & > .imagesLayout {
    z-index: 3;

    @media (--viewport-lg) {
      z-index: 0;
      max-height: 100lvh;
      top: 0;
    }
  }

  & > .shadowCastingLayout {
    z-index: -1;
    position: absolute;
    width: min(1080px, 100vw);
    max-height: none;
    max-width: none;
    right: 0;
    top: 0;
  }

  & > .contentLayout {
    z-index: 1;

    @media (--viewport-lg) {
      grid-area: 1 / 3 / 2 / 4;
    }
  }

  & > .gradientBlurLayout {
    z-index: -2;
    position: absolute;
    height: 200lvh;
    width: 100%;
    bottom: 0;
  }

  & > .mobileApplyButtonLayout {
    z-index: 2;
    position: fixed;
    width: 100%;
    top: 0;
  }
}

.componentImages {
  @media (--viewport-lg) {
    position: sticky;
  }

  & > .sectionLayout {
    height: 100%;
  }
}

.images {
  & > .imagesLayout {
    height: 100%;
  }
}

.componentContent {
  z-index: 0;
  position: relative;
  display: grid;
  padding-top: var(--size-30);

  @media (--viewport-md) {
    padding-top: var(--size-48);
  }

  @media (--viewport-lg) {
    padding-top: 0;
  }

  & > .mobileHeroImage {
    z-index: -1;
  }

  & > .introduction {
    margin-bottom: var(--size-40);
  }

  & > .jobDescription,
  & > .benefits,
  & > .relatedContent,
  & > .form {
    margin-bottom: var(--size-80);

    @media (--viewport-lg) {
      margin-bottom: var(--size-120);
    }
  }

  & > .relatedJobs {
    max-width: 100%;
    margin-bottom: var(--size-100);

    @media (--viewport-md) {
      margin-bottom: var(--size-120);
    }
  }

  & > .benefits {
    margin-top: calc(-1 * var(--size-48));

    @media (--viewport-lg) {
      margin-top: 0;
    }
  }
}

.mobileHeroImage {
  overflow: hidden;

  @media (--viewport-md) {
    aspect-ratio: 6 / 7;
  }

  @media (--viewport-lg) {
    display: none;
  }
}

.introduction,
.jobDescription,
.closingText,
.relatedContent {
  padding: var(--container-padding);
}

.jobDescription {
  & > :not(:last-child) {
    margin-bottom: var(--size-70);
  }
}

.introduction {
  position: relative;

  & > .introductionLayout {
    @media (--viewport-lg) {
      min-height: 100lvh;
    }
  }

  & > .hashSetterLayout {
    position: absolute;
    width: 80%;
    height: 100%;
  }
}

.benefits {
  position: relative;
  padding-left: min(5vw, calc(2.5vw + 24px));

  @media (--viewport-lg) {
    padding: var(--container-padding);
  }

  & > .hashSetterLayout {
    position: absolute;
    width: calc(100% - min(5vw, calc(2.5vw + 24px)));
    height: 100%;
  }
}

.form {
  position: relative;

  @media (--viewport-lg) {
    padding: var(--container-padding);
  }

  & > .hashSetterLayout {
    position: absolute;
    width: 80%;
    height: 100%;
  }
}

.relatedJobs {
  overflow: hidden;

  & > .highlightedJobsLayout {
    width: 100vw;
    max-width: 100%;

    @media (--viewport-md) {
      width: auto;
    }
  }
}

.jobDescriptionContentSections {
  position: relative;

  & > .headingLayout {
    margin-bottom: var(--size-30);
  }

  & > .hashSetterLayout {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

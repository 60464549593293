.componentBase {
  --fallback-display-mode: none;

  position: relative;

  &.isSuspended {
    --fallback-display-mode: block;
  }

  & > .video {
    height: 100vh;
    width: 100vw;
  }

  & > .fallbackImageLayout {
    height: 100vh;
    width: 100vw;
  }
}

.video {
  object-fit: cover;

  &:stalled {
    display: none;
  }

  &.isSuspended {
    display: none;
  }
}

.fallbackImageLayout {
  display: var(--fallback-display-mode);

  & > * {
    height: 100%;
  }
}

.componentWithFullscreenToggle {
  z-index: 0;
  position: relative;

  & > .fullscreenButton {
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
  }

  & > .videoLayout {
    width: 100%;
    height: 100%;
  }
}

.fullscreenButton {
  cursor: none !important;

  &.isFullscreen {
    pointer-events: none;
  }
}

.component {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: var(--primary-background-color);
  padding-top: var(--size-60);
  padding-bottom: var(--size-60);
  padding-left: var(--container-x-padding);
  padding-right: var(--container-x-padding);
  gap: var(--size-14);

  & > .nameElement {
    margin-bottom: var(--size-14);
  }

  & > .quote {
    max-width: var(--container-max-width-md);
  }
}

.avatarElement {
  border-radius: var(--border-radius-full);
  overflow: hidden;

  & > .avatarImageLayout {
    width: var(--size-80);
    height: var(--size-80);
  }
}

.nameElement {
  line-height: var(--line-height-small-heading);
  font-family: var(--font-family-heading);
  font-weight: var(--font-weight-400);
  font-size: var(--font-size-18-22);
  color: var(--primary-color);
  letter-spacing: -0.01em;
  text-align: center;
  font-style: italic;
}

.componentBlockquote {
  font-family: var(--font-family-heading);
  letter-spacing: var(--letter-spacing-heading);
  line-height: var(--line-height-heading);
  font-size: var(--font-size-34);
  font-size: var(--font-size-34-48);
  quotes: none;
}

.quoteHighlightElement {
  --progress: 0%;
  --from: var(--testimonial-accent) var(--progress);
  --to: var(--testimonial-color) calc(var(--progress) + 1%);

  word-break: break-word;
  hyphens: auto;
  background-image: linear-gradient(90deg, var(--from), var(--to));
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;

  @media (--viewport-md) {
    hyphens: none;
    word-break: keep-all;
  }
}

.quote {
  text-align: center;
}

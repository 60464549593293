.componentPage {
  min-height: 100%;
  display: flex;
  flex-direction: column;

  & > .resultsLayout {
    flex-grow: 1;
  }
}

.hero {
  padding-top: var(--size-100);
  display: flex;
  justify-content: center;
}

.componentFilters {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  gap: var(--size-12);
  padding: var(--size-24) 0;

  @media (--viewport-md) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
  }
}

.componentNoResultsMessage {
  padding: 100px 0;
  font-family: var(--font-family-heading);
  font-weight: var(--font-weight-400);
  font-size: var(--font-size-25);
  font-size: var(--font-size-25-30);
  line-height: var(--line-height-small-heading);
  text-align: center;
}

.list {
  display: grid;
  gap: var(--size-64);
  padding-bottom: var(--size-80);
}

.componentResults {
  padding-top: var(--size-100);
  color: var(--color-primary);
}

.componentItem {
  display: grid;
  text-align: left;
  column-gap: var(--size-32);
  grid-template-columns: 1fr auto;
  grid-template-areas:
    'title  button'
    'text   button';
  position: relative;

  & > .titleLayout {
    grid-area: title;
    justify-self: start;
  }

  & > .detailsLayout {
    grid-area: text;
    justify-self: start;
  }

  & > .buttonLayout {
    grid-area: button;
    position: static;
  }
}

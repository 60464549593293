.componentSm {
  font-size: var(--font-size-16);
}

.componentMd {
  font-size: var(--font-size-25);
}

.componentItalicMd {
  font-size: var(--font-size-25);
}

.componentItalicLg {
  font-size: var(--font-size-30);
}

.italic {
  font-style: italic;
}

.uppercase {
  text-transform: uppercase;
}

.componentBase {
  font-family: var(--font-family-heading);
  line-height: var(--line-height-heading);
  font-weight: var(--font-weight-400);
  color: var(--primary-color-accent);
  text-wrap: balance;
}

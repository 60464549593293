.componentBase,
.componentFieldsetAndError {
  display: grid;
  grid-template-columns: 1fr 0px;
  grid-template-rows: repeat(3, max-content);
  align-items: center;
  grid-template-areas:
    'label  label'
    'input  icon'
    'error  ...';
  transition: grid-template-columns 150ms ease;

  &.hasError {
    grid-template-columns: 1fr calc(var(--size-24) + var(--size-16));
  }

  & > .labelLayout {
    grid-area: label;
    margin-bottom: var(--size-8);
  }

  & > .errorIconContainer {
    grid-area: icon;
    width: var(--size-24);
    height: var(--size-24);
    margin-left: var(--size-10);
  }

  & > .errorLayout {
    grid-area: error;
    margin-top: var(--size-8);
  }
}

.errorIconContainer {
  color: var(--color-error);
}

.formFieldText,
.formFieldEmail,
.numberInputElement,
.formFieldTextarea {
  border-top: 1px solid transparent;
  background-color: var(--form-input-background);
  padding: var(--size-12) var(--size-16);
  color: var(--primary-color);

  &::placeholder {
    color: var(--form-input-placeholder-color);
    font-style: italic;
  }

  &.hasError {
    border-color: var(--color-error);
  }
}

.componentSelectBase {
  &.hasError {
    border-color: var(--color-error);
  }
}

.componentTextLightSimple {
  border: 1px solid var(--primary-color);
  padding: var(--size-12) var(--size-16);
  color: var(--primary-color);

  &::placeholder {
    color: var(--color-dark-500);
    font-style: italic;
  }

  &.hasError {
    border-color: var(--color-error);
  }
}

.numberInputElement {
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
  }
}

.formFieldTextarea {
  resize: none;

  &::placeholder {
    color: var(--primary-color--40);
  }
}

.formFieldFile {
  border: 1px solid var(--primary-color--40);

  &.hasError {
    border-color: var(--color-error);
  }

  & > * {
    height: var(--size-60);
    width: 100%;
  }
}

.layoutInputFile,
.inputFileLabel,
.inputFileClose {
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: var(--font-size-12);
}

.inputFileClose {
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: var(--font-size-12);
  gap: var(--size-12);

  & > .iconLayout {
    width: var(--size-12);
    height: var(--size-12);
  }
}

.layoutInputFile {
  position: relative;
  z-index: 0;

  & > .inputFileLabel {
    position: absolute;
    inset: 0;
    width: 100%;
    height: var(--size-60);
  }
}

.inputFile {
  position: relative;
  z-index: 0;
  cursor: pointer;

  &::file-selector-button {
    visibility: hidden;
    width: 100%;
    height: 100%;
    margin: var(--size-10);
  }
}

.formFieldSelectBase {
  position: relative;
  display: flex;
  z-index: 0;

  & > .dropdownIcon {
    width: 18px;
    position: absolute;
    right: var(--size-16);
    z-index: -1;
  }

  & > .select {
    width: 100%;
  }
}

.dropdownIcon {
  transform: translateY(50%);
  opacity: 0.9;
  pointer-events: none;
}

.componentCheckboxGroup,
.componentRadioGroup {
  border: none;
  padding: 0;

  & > .fieldsetLabel,
  & > .radioGroupLegend {
    margin-bottom: var(--size-20);
  }
}

.radioGroupChildrenContainer {
  display: flex;
  flex-direction: column;
  gap: var(--size-22);
}

.radioOption {
  position: relative;
  display: flex;
  align-items: center;
  gap: var(--size-16);
  color: var(--color-white);
  cursor: pointer;

  & > .radioLabelElement {
    position: static;
  }

  & > .inputRadioElement {
    order: -1;
    width: var(--size-24);
    height: var(--size-24);
  }
}

.select {
  cursor: pointer;
}

.componentDescription {
  font-size: var(--font-size-16);
}

.inputCheckboxWrapper {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;

  & > .checkboxLabel {
    position: static;
  }

  & > .inputCheckbox {
    position: absolute;
    margin: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  & > .checkbox {
    order: -1;
  }
}

.componentInputNumber {
  position: relative;

  & > .numberInputElement {
    width: 100%;
  }

  & > .numberInputIncrementButton {
    height: calc(50% - var(--size-12));
    position: absolute;
    right: var(--size-16);

    &:first-of-type {
      top: 0;
    }

    &:last-of-type {
      bottom: 0;
    }
  }
}

.numberInputIncrementButton {
  appearance: none;
  display: inline-flex;

  & > * {
    display: flex;
    height: var(--size-6);
  }

  &:first-of-type {
    align-items: flex-start;
    padding-top: var(--size-16);
  }

  &:last-of-type {
    align-items: flex-end;
    padding-bottom: var(--size-16);
  }
}

.componentCheckbox,
.componentInputCheckboxSingle,
.checkboxOption {
  position: relative;
  display: flex;
  align-items: center;
  gap: var(--size-16);
  color: var(--color-white);
  cursor: pointer;

  & > .checkboxLabel {
    position: static;
  }

  & > .inputCheckbox {
    order: -1;
    width: var(--size-24);
    height: var(--size-24);
    flex-shrink: 0;
  }
}

.checkboxLabel {
  user-select: none;
}

.inputRadioElement,
.inputCheckbox {
  display: grid;
  place-content: center;
  border: 1px solid var(--color-clay-300);
  appearance: none;

  &::before {
    content: "";
    width: calc(var(--size-24) - 6px);
    height: calc(var(--size-24) - 6px);
    box-shadow: inset 1em 1em 1em var(--color-clay-300);
    transform: scale(0);
  }

  &:checked::before {
    transform: scale(1);
  }
}

.inputRadioElement {
  border-radius: var(--border-radius-full);

  &::before {
    border-radius: var(--border-radius-full);
  }
}

.checkbox {
  border: 1px solid black;
  cursor: pointer;
  color: transparent;

  &.isChecked {
    color: inherit;
  }
}

.componentLabel {
  position: relative;
  display: flex;
  justify-content: space-between;
  cursor: default;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

.innerLabel {
  font-size: var(--font-size-16);
  letter-spacing: var(--letter-spacing-label);
  color: var(--color-clay-300);
}

.fieldsetLabel {
  font-size: var(--font-size-16);
  letter-spacing: var(--letter-spacing-label);
  color: var(--color-clay-300);
}

.error {
  font-size: var(--font-size-14);
  color: var(--color-error);
}


.component {
  --stepper-color: var(--primary-color-accent);
  --zero-index-position: calc(var(--position) - 1);
  --stepper-partition: (100% / (var(--count) - 1));
  --gradient-position: calc(var(--zero-index-position) * var(--stepper-partition));
  --color-inactive: var(--primary-color-accent);
  --color-active: var(--primary-color);

  display: flex;
  justify-content: space-between;
  position: relative;

  & > .stepElement {
    height: var(--size-16);
    width: var(--size-16);
  }

  &::before {
    content: '';
    position: absolute;
    background: linear-gradient(
      to right,
      var(--color-active) var(--gradient-position),
      var(--color-inactive) calc(var(--gradient-position) + 10%)
    );
    clip-path: inset(48% 0 48% 0);
    height: 100%;
    width: 100%;
    inset: 0;
  }
}

.stepElement {
  --color-active: var(--primary-color);
  --color-inactive: var(--primary-color-accent);

  display: flex;
  border-radius: var(--border-radius-full);
  background-color: var(--color-inactive);
  transition: all vr(--motion-duration-200) var(--motion-ease);
  position: relative;

  &.isActive {
    background-color: var(--color-active);

    &::before {
      color: var(--color-active);
    }
  }

  &::before {
    color: var(--color-inactive);
    counter-reset: step var(--step-label);
    content: attr(data-key) ' ' counter(step);
    letter-spacing: var(--letter-spacing-label);
    font-weight: var(--font-weight-400);
    font-size: var(--font-size-16);
    margin-bottom: var(--size-6);
    transform: translateX(-50%);
    position: absolute;
    white-space: pre;
    bottom: 100%;
    left: 50%;
  }
}

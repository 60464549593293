.component {
  mix-blend-mode: var(--shadow-casting-blend-mode);
  position: relative;
  z-index: 0;

  & > .shadowCastingComponentLayout {
    z-index: 1;
    position: absolute;
    right: 0;
    top: 0;
  }

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    background-color: var(--primary-background-color);
    opacity: 0.15;
  }
}

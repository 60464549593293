.component {
  display: flex;
  column-gap: var(--size-20);
  flex-direction: column;
  align-content: center;
  align-items: center;

  @media (--viewport-md) {
    column-gap: var(--size-30);
    flex-direction: row;
    align-items: flex-start;
  }

  & > .qrLayout {
    width: var(--size-100);
  }

  & > .content {
    flex-grow: 1;
  }
}

.content {
  padding: var(--size-10) 0;
  text-align: center;

  @media (--viewport-md) {
    text-align: left;
  }

  & > .titleLayout {
    margin-bottom: var(--size-14);
  }

  & > .buttonLayout {
    width: 100%;
  }
}

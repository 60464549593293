.twoFields {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--size-24);

  @media (--viewport-md) {
    grid-template-columns: repeat(2, 1fr);
    gap: var(--size-10);
  }
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  gap: var(--size-10);

  @media (--viewport-md) {
    flex-direction: row;
  }

  & > * {
    width: 100%;
  }
}

.componentWithSteps {
  display: contents;

  & > .stepperLayout {
    margin-top: var(--size-20);
    margin-left: var(--size-10);
    margin-right: var(--size-10);
  }
}

.componentSubmitButton {
  vertical-align: middle;

  & > .loaderLayout {
    margin-right: var(--size-8);
  }
}

.componentLoader {
  display: flex;
  justify-content: center;
}

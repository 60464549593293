/* This prevents height issues in Safari. */
.component_rootBase {
  height: 100%;
  width: 100%;
  cursor: pointer;

  &.hidePointerCursor {
    cursor: default;
  }
}

.component {
  position: relative;
  z-index: 0;

  & > .overlay {
    z-index: 2;
    position: absolute;
    height: 100%;
    width: 100%;
    inset: 0;
  }
}

.overlay {
  --icon-size: 50px;

  z-index: 0;
  position: relative;

  @media (--viewport-md) {
    --icon-size: 80px;
  }

  & > .cursorContainerLayout {
    z-index: 1;
    position: absolute;
    height: var(--icon-size);
    width: var(--icon-size);
    left: calc(50% - (var(--icon-size) / 2));
    top: calc(50% - (var(--icon-size) / 2));
  }
}

.componentWithContext {
  --overlay-opacity: 1;

  position: relative;
  z-index: 0;

  &.isPlaying {
    --overlay-opacity: 0;
  }

  &::before {
    content: '';
    position: absolute;
    opacity: var(--overlay-opacity);
    transition: opacity var(--motion-duration-150) var(--motion-ease-in-out);
    background: linear-gradient(to bottom, transparent 50%, var(--color-black--40));
    pointer-events: none;
    z-index: 2;
    inset: 0;
  }
}

.contextWrapper {
  display: flex;
  align-items: flex-end;
  padding: var(--size-20);
}

.overlayWithContext {
  --icon-size: 50px;

  @media (--viewport-md) {
    --icon-size: 80px;
  }

  display: grid;
  align-content: end;
  position: relative;
  align-items: center;
  justify-content: start;
  padding: var(--size-30);
  column-gap: var(--size-20);
  transform-origin: bottom left;
  grid-template-columns: var(--icon-size) auto;
  grid-template-areas: 'play text';

  & > .cursorContainerLayout {
    grid-area: play;
    width: var(--icon-size);
    height: var(--icon-size);
  }

  & > .textWrapper {
    grid-area: text;
    align-self: end;
    height: 100%;
  }
}

.textWrapper {
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-areas:
    'title'
    'description';

  &:not(:has(.descriptionLayout)) {
    grid-template-rows: auto;
    grid-template-areas: 'title';
  }

  & > .titleLayout {
    grid-area: title;
    align-self: end;
  }

  & > .descriptionLayout {
    grid-area: description;
    align-self: start;
  }
}

.componentCover {
  overflow: hidden;
}

.content {
  &:empty {
    display: none;
  }
}

.componentBase {
  display: grid;
  overflow: hidden;
  position: relative;
  aspect-ratio: 16 / 9;
  z-index: 0;

  & > * {
    grid-area: 1 / -1;
  }

  & > .controlsLayout {
    z-index: 4;
    align-self: end;
    min-height: var(--size-120);
    height: max-content;
  }

  & > .fullscreenControlsLayout {
    width: 100vw;
  }

  & > .children {
    width: 100%;
    z-index: 5;
  }

  & > .content {
    z-index: 2;
  }

  & > .posterLayout {
    max-width: 100%;
    z-index: 1;
  }

  & > .player {
    z-index: 0;
  }
}

.children {
  display: grid;
  user-select: none;
  pointer-events: none;

  & > * {
    grid-area: 1 / -1;
  }

  &.isIgnored {
    pointer-events: none;
  }
}

.componentControls {
  display: grid;
  justify-content: space-between;
  align-items: end;
  grid-template-columns: auto 1fr auto auto;
  color: var(--color-white);
  padding: var(--size-18);
  gap: var(--size-20);
  position: relative;

  @media (--viewport-md) {
    align-items: center;
    padding: var(--size-30);
  }

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(to top, var(--color-black--40), transparent);
    pointer-events: none;
  }

  & > .buttonLayout {
    height: var(--size-24);
    width: var(--size-24);
  }

  & > .progress {
    width: 100%;
    height: var(--size-24);
  }
}

.componentButton {
  position: relative;
  color: var(--color-white);
  transition: color var(--motion-duration-150) var(--motion-ease-in-out);

  &.lowerOpacity {
    color: var(--color-clay-400);
  }

  & > .iconLayout {
    height: var(--size-24);
    width: var(--size-24);
  }
}

.progress {
  --active-color: var(--color-clay-400);

  --progress-part-a: transparent calc(var(--size-12) - 1px);
  --progress-part-b: calc(var(--size-12) + 1px);
  --progress-part-c: transparent calc(var(--size-12) + 1px);

  cursor: pointer;
  appearance: none;
  border: none;
  border-radius: 3px;
  position: relative;
  background: linear-gradient(to bottom, var(--progress-part-a), white var(--progress-part-b), var(--progress-part-c));
  transition: all 100ms ease, background-color 150ms ease-in-out;

  &:hover {
    --active-color: var(--color-clay-300);

    cursor: pointer;
  }

  &:not(:active)::after {
    transition: transform 100ms ease, background 150ms ease-in-out;
  }

  &:active {
    cursor: grabbing;

    &::after {
      transition: transform 100ms ease;
    }
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    box-shadow: none;
    height: var(--size-20);
    width: var(--size-20);
    border-radius: 50%;
    background-color: var(--active-color);
  }

  &::-moz-range-track,
  &::-webkit-progress-bar,
  &::-webkit-progress-value,
  &::-webkit-slider-runnable-track {
    background: linear-gradient(to bottom, var(--progress-part-a), white var(--progress-part-b), var(--progress-part-c));
    border-radius: 3px;
    transition: all 100ms ease;
  }
}

.poster {
  user-select: none;
}

.player {
  pointer-events: none;

  &.isPlaying {
    pointer-events: auto;
  }

  & > iframe {
    width: 100%;
    height: 100%;
  }
}

.jaggedEdgesFix {
  backface-visibility: hidden;
  outline: 1px solid var(--primary-background-color);
  padding: 1px;
}

.isPlaying {
  cursor: default;
}

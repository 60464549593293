.component {
  display: flex;
  justify-content: center;
}

.componentCoverDynamicScale {
  object-fit: cover;

  &.isWide > * {
    width: 100%;
  }

  &.isNarrow > * {
    height: 100%;
  }
}

.componentCoverBase {
  & > * {
    width: 100%;
    height: 100%;
  }
}

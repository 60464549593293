.item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--color-black--10);
  transition: border-color var(--motion-duration-150);
  padding: var(--size-24) 0;
  font-family: var(--font-family-heading);
  font-weight: var(--font-weight-400);
  position: relative;

  &:hover {
    border-color: var(--color-black--15);
  }

  & > .content {
    flex-grow: 1;
  }

  & > .buttonContextLayout {
    align-self: center;
    position: static;
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title {
  font-size: var(--font-size-22);
  letter-spacing: var(--letter-spacing-heading);
}

.label {
  color: var(--primary-color-accent);
  font-size: var(--font-size-14);
  text-transform: uppercase;
}

.buttonContext {
  position: relative;

  & > .buttonLayout {
    position: static;
  }
}

.localSectionSand {
  position: relative;
  z-index: 0;

  & > .shadowCastingLayout {
    position: absolute;
    z-index: 0;
    inset: 0;
  }

  & > :not(.shadowCastingLayout) {
    position: relative;
    z-index: 1;
  }
}

.introductionContainer {
  padding-top: var(--size-140);
}

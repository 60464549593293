.component {
  & > .playlist {
    width: 100%;
    height: 400px;

    @media (--viewport-md) {
      height: 500px;
    }
  }
}

.playlist {
  border: 0;
}

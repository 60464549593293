.component_root {
  height: calc(var(--scroller-height) * 1svh);
  padding-top: calc(var(--scroller-height) * 1svh);
}

.component {
  --scroller-height: 0;
  --max-video-height: 70svh;
  --desired-video-width: min(90vw, var(--container-max-width-xl), calc(var(--max-video-height) * (16 / 9)));

  scroll-snap-type: x mandatory;
  position: relative;
  z-index: 0;

  & > .animatorLayout,
  & > .floatingVideoLayout {
    position: fixed;
    max-width: 100%;
    height: 100lvh;
    width: 100vw;
    left: 0;
    top: 0;
  }

  & > .overlay {
    z-index: 1;
    position: fixed;
    inset: 0;
  }

  & > .floatingVideoLayout {
    z-index: 0;
  }
}

.videoContainer {
  position: relative;

  & > * {
    position: absolute;
    height: 100%;
    width: 100%;
    inset: 0;
  }
}

.componentVideo {
  z-index: 0;
  display: grid;
  overflow: hidden;
  position: relative;
  gap: var(--size-30);
  justify-items: center;
  padding: var(--size-30);
  grid-template-rows: auto max-content min-content auto;
  grid-template-columns: 1fr;
  grid-template-areas:
    '...'
    'video'
    'buttons'
    '...';

  @media (--viewport-md) {
    grid-template-columns: 1fr var(--desired-video-width) 1fr;
    grid-template-rows: auto calc(var(--desired-video-width) / (16 / 9)) min-content auto;
    grid-template-areas:
      '...  ...     ...'
      '...  video   ...'
      '...  buttons ...'
      '...  ...     ...';
  }

  & > .imgLayout {
    z-index: -1;
    position: absolute;
    height: 100%;
    width: 100%;
    inset: 0;
  }

  & > .buttons {
    grid-area: buttons;
    width: 100%;

    @media (--viewport-md) {
      width: max-content;
    }
  }

  & > .fullScreenElement {
    grid-area: video;
    width: 100%;
  }
}

.videoWrapper {
  display: grid;
  place-content: center;
  position: relative;
  z-index: 0;
  box-shadow:
    0 0 80px 0 #00000040,
    0 0 50px 0 #00000030,
    0 0 30px 0 #00000015;

  & > * {
    grid-area: 1 / -1;
  }
}

.fullScreenElement {
  display: grid;
  place-content: center;
}

.closeButton {
  position: relative;
  z-index: 0;

  &::before {
    z-index: -1;
    content: '';
    position: absolute;
    background: linear-gradient(45deg, transparent 50%, var(--color-black--40));
    height: 150px;
    width: 150px;
    right: -20px;
    top: -20px;
  }
}

.buttons {
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: var(--size-20);

  @media (--viewport-md) {
    flex-direction: row;
    align-items: flex-end;
  }
}

.playButton {
  & > * {
    height: var(--size-80);
    width: var(--size-80);

    @media (--viewport-md) {
      height: var(--size-100);
      width: var(--size-100);
    }
  }
}

.componentFrameAnimator {
  display: grid;
  scroll-snap-align: center;
  contain: layout paint style;
  place-content: center;
  position: relative;

  & > .floatingImageLayout {
    position: absolute;
    inset: 0;
  }
}

.overlay {
  --progress-ring-size: 50px;

  @media (--viewport-md) {
    --progress-ring-size: 60px;
  }

  display: grid;
  gap: var(--size-10);
  place-content: center;
  justify-items: center;
  pointer-events: none;
  user-select: none;
  padding-bottom: env(safe-area-inset-bottom);
  grid-template-columns: auto minmax(0, min(65vw, 950px)) auto;
  grid-template-rows: 15svh 1fr var(--progress-ring-size) var(--size-10) 1lh var(--size-30);
  grid-template-areas:
    '...  ...       ...'
    '...  title     ...'
    '...  progress  ...'
    '...  ...       ...'
    '...  hint      ...'
    '...  ...       ...';

  @media (--viewport-md) {
    gap: var(--size-40);
    grid-template-columns: auto minmax(0, min(65vw, var(--container-max-width-md))) auto;
    grid-template-rows: 3fr 2fr var(--progress-ring-size) 1fr 1lh var(--size-40);
  }

  & > .titleLayout {
    grid-area: title;
    max-width: 75vw;

    @media (--viewport-md) {
      max-width: 50vw;
    }
  }

  & > .progressLayout {
    grid-area: progress;
    height: var(--progress-ring-size);
    width: var(--progress-ring-size);
  }

  & > .scrollHintLayout {
    grid-area: hint;
  }
}

.componentTextAnimator {
  display: flex;
  text-align: center;
  align-items: center;
  font-size: var(--font-size-45-80);
  font-weight: var(--font-weight-400);
  font-family: var(--font-family-heading);
  line-height: var(--line-height-heading);

  @supports (text-wrap: prettier) {
    text-wrap: prettier;
  }
}

.componentProgressCircle {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  color: var(--primary-color);
  font-size: var(--font-size-14);
  border-radius: 50%;

  @media (--viewport-md) {
    font-size: var(--font-size-16);
  }

  &::before {
    counter-reset:
      count var(--progress-count)
      total var(--progress-total);
    content: counter(count) '–' counter(total);
  }

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 50%;
    mask: radial-gradient(circle calc(var(--progress-ring-size) / 2), #0000 90%, #fff 92%);
    background:
      radial-gradient(closest-side, var(--primary-background-color) 79%, transparent 80% 100%),
      conic-gradient(var(--primary-color) calc(var(--progress) * 1%), var(--primary-color-accent) 0);
  }
}

.progress {
  visibility: hidden;
  width: 0;
  height: 0;
}

.component {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  text-transform: uppercase;
  font-size: var(--font-size-12);
}

.label {
  opacity: 0.6;
}

.component {
  --gap-size: var(--size-40);
  --max-width-sm: 300px;

  display: flex;
  flex-direction: column;
  gap: var(--gap-size);

  @media (--viewport-md) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: min-content;
    grid-template-areas:
      'video   video'
      'leftCol rightCol';
  }

  & > .videoCell {
    @media (--viewport-md) {
      grid-area: video;
    }
  }

  & > .columnLeftLayout {
    @media (--viewport-md) {
      grid-area: leftCol;
    }
  }

  & > .columnRightLayout {
    @media (--viewport-md) {
      grid-area: rightCol;
    }
  }
}

.videoCell {
  display: flex;
  align-items: center;
  justify-content: center;

  & > * {
    min-width: 100%;

    @media (--viewport-md) {
      min-width: 50%;
      max-width: calc(2 * var(--max-width-sm) + var(--gap-size));
    }
  }
}

.componentColumn {
  display: flex;
  flex-direction: column;
  gap: var(--gap-size);
}

.componentItem {
  & > .cardLayout {
    width: 100%;
  }

  &.isLeft {
    @media (--viewport-md) {
      display: flex;
      justify-content: flex-end;
    }
  }

  & > .imageLayout {
    max-width: 100%;

    @media (--viewport-md) {
      max-width: 420px;
    }
  }

  & > .videoLayout {
    width: 100%;
    height: 100%;
  }

  & > .withoutImageLayout {
    width: 100%;

    @media (--viewport-md) {
      height: auto;
      width: 300px;
    }
  }
}

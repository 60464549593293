.component {
  --fallback-display-mode: none;

  z-index: 0;
  position: relative;
  aspect-ratio: 1 / 1;
  transform: translate3d(0, 0, 0); /** Fixes blend issues in Safari. */
  mix-blend-mode: var(--shadow-casting-blend-mode);
  pointer-events: none;
  user-select: none;
  mask-image: radial-gradient(
    ellipse at top right,
    black 65%,
    rgba(0, 0, 0, 0) 100%
  );

  &.isSuspended {
    --fallback-display-mode: block;
  }

  & > .fallbackElement {
    position: absolute;
    height: 1080px;
    width: 1080px;
    right: 0;
    top: 0;
  }
}

.componentVideoImpl {
  &:stalled,
  &.isSuspended {
    display: none;
  }
}

.fallbackElement {
  display: var(--fallback-display-mode);
}
